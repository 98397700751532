@import "../variables";

.meta-container {
    .meta-item {
        padding: 20px 30px;
        background-color: $fifth;
        border-radius: 10px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0px;
        }
        &.custom-nft-button {
            background: transparent;
            border: 1px solid $primary;
            border-radius: 5px;
            transition: all 0.3s;
            text-align: center;
            color: $white;
            cursor: pointer;
            font-size: 18px;

            &:hover {
                background: $primary;
            }
        }

        .header {
            p {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0px;
            }
            svg {
                font-size: 20px;
                color: $third;
                transition: transform 0.4s;
            }
            &.active {
                svg {
                    transform: rotate(180deg);
                }
            }
            // transition: all 0.4s;
            // transition-delay: 0.2s;
        }
        .meta-content {
            // margin-top: 20px;
            .metadata-card {
                height: 100px;
                width: 182px;
                padding: 20px;
                border: 1px solid $seventh;
                text-align: center;
                background: $fifth;
                border-radius: 5px;
                margin-right: 10px;
                margin-bottom: 10px;

                .meta-type {
                    font-size: 16px;
                    font-weight: bold;
                    color: $white;
                    margin-bottom: 0px;
                    max-width: 145px;
                }
                .meta-value {
                    margin-top: 10px;
                    font-size: 16px;
                    color: $third;
                    margin-bottom: 0px;
                    max-width: 150px;
                }
            }
        }
        .properties {
            min-width: 400px;
            .prop-name {
                font-size: 16px;
                color: $third;
                margin-bottom: 0px;
                max-width: 150px;
            }
            .prop-value {
                @extend .prop-name;
                color: $white;
                max-width: 250px;
                width: 230px !important;
            }
            svg {
                background-color: $fifth;
            }
        }
    }
}

// animation
.meta-content {
    height: 0px;
    transition: height 0.4s;
    transition-delay: 0, 0.2s;
    margin-top: 0px;
    transition-property: height, margin-top;
    overflow: hidden;
    .metadata-card {
        transform: scaleY(0);
        transition: transform 0.2s;
        transform-origin: top;
    }
    &.animateNftMetaPanel {
        // @todo - make this height auto or add a scrollbar
        height: 175px;
        overflow-y: auto;
        margin-top: 20px;
        .metadata-card {
            transform: scaleY(1);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */

        &.meta-details {
            // overflow: visible;
            height: 340px;
            @media screen and (max-width: 768px) {
                overflow-x: scroll;
            }
        }
    }
}

.custom-toolTip {
    .tooltip-inner {
        max-width: 500px !important;
        background-color: $secondary !important;
    }
}
