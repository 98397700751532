@import "../variables";

.thumbnail-select-modal {
    max-width: 762px !important;
    .modal-content {
        .modal-body {
            padding: 40px;

            .header {
                h3 {
                    font-size: 36px;
                    color: $secondary;
                    font-weight: bold;
                    line-height: 36px;
                }
                p {
                    font-size: 14px;
                    color: $forth;
                    margin: 20px 0px 0px 0px;
                }
            }
            .thumbnails {
                display: flex;
                flex-wrap: wrap;

                .image {
                    margin-right: 20px;
                    margin-top: 16px;
                    border-radius: 5px;
                    overflow: hidden;
                    object-fit: cover;
                    width: 120px;
                    height: 120px;

                    &:last-child {
                        margin-right: 0px;
                    }

                    &.active {
                        img {
                            border: 2px solid $primary;
                        }
                    }

                    &.custom {
                        border: 1px dashed $ninth;
                        border-radius: 5px;
                        width: 120px;
                        height: 120px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .info {
                            img {
                                width: 20px;
                                height: 20px;
                                margin-bottom: 5px;
                                border-radius: 5px;
                            }
                            p {
                                max-width: 54px;
                                color: $forth;
                                margin: auto;
                                font-size: 12px;
                                line-height: 15px;
                            }
                        }
                    }
                    img {
                        width: 120px;
                        height: 120px;
                        object-fit: cover;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
                span {
                    .react-loading-skeleton {
                        &.image {
                            margin-right: 16px;
                        }
                    }
                    &:last-child {
                        .react-loading-skeleton {
                            &.image {
                                margin-right: 0px;
                            }
                        }
                    }
                }

                @media screen and (max-width: 374px) {
                    justify-content: center;
                }
            }
            .buttons {
                display: flex;
                .btn {
                    margin-top: 21px;
                    margin-left: auto;
                }
            }
        }
    }
}
