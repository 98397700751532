@import "../variables";

.integration-page {
    color: white;
    margin: 180px auto;

    @media screen and (max-width: 991px) {
        margin-top: 40px;
    }
    @media screen and (max-width: 360px) {
        margin-top: 20px;
    }

    .integration-options {
        .features {
            margin-top: 85px;
            margin-bottom: 85px;

            &:nth-child(even) {
                .row {
                    flex-direction: row-reverse;
                }
            }

            @media screen and (max-width: 768px) {
                margin-top: 45px;
                margin-bottom: 45px;
            }
        }
    }
    .options-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 0 30px;

        .heading {
            line-height: 50px;
            @media screen and (max-width: 1021px) {
                line-height: 36px;
            }
        }
        // select the direct child only
        & > ul {
            li {
                line-height: 25px;
                padding-bottom: 15px;
                padding-left: 30px;
                color: $third;
                font-size: 18px;

                @media screen and (max-width: 1199px) {
                    font-size: 16px;
                }
            }
            & > li {
                background-image: url("../../assets/images/check.svg");
                background-repeat: no-repeat;
                background-position: 0px 5px;
            }
        }
    }
    .banner {
        .introduction {
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: flex-start;
            .description {
                font-size: 24px;
                line-height: 36px;
            }
            @media screen and (max-width: 450px) {
                .description {
                    font-size: 18px;
                }
                gap: 20px;
            }
        }
        @media screen and (max-width: 991px) {
            .introduction {
                align-items: center;
                text-align: center;
            }
            .hero-image {
                max-width: 400px;
                padding: 0px 20px;
            }
            padding-bottom: 30px;
        }
        @media screen and (max-width: 550px) {
            .hero-image {
                max-width: 300px;
            }
        }
        @media screen and (max-width: 360px) {
            .hero-image {
                max-width: 200px;
            }
        }
    }
    .explore {
        margin-top: 100px;
        @media screen and (max-width: 768px) {
            margin-top: 40px;
            .btn {
                margin-top: 0;
            }
        }
    }
}
