@import "../variables";

.more-categories-panel {
    max-width: 1316px;
    width: 100%;
    height: 0px;
    margin-top: 0px;
    overflow: hidden;
    opacity: 0;
    &.open {
        height: 498px;
        margin-top: 30px;
        opacity: 1;
    }
    background-color: $secondary;
    border-radius: 10px;
    transition: height 0.3s, margin 0s;

    .categories-header {
        padding: 21px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="text"] {
            width: 250px;
            height: 38px;
            border-radius: 5px;
            border: 1px solid $seventh;
            font-size: 14px;
            color: $third;
            padding: 10px 20px;
            background-color: transparent;
            @media screen and (max-width: 359px) {
                width: 200px;
            }
            @media screen and (max-width: 300px) {
                width: auto;
                max-width: 150px;
                width: 100%;
            }
            @media screen and (max-width: 250px) {
                width: 100%;
                max-width: 100%;
            }
        }
        .latters {
            @media screen and (max-width: 768px) {
                order: 3;
                padding-top: 21px;
            }
            button {
                color: $third;
                border: 0;
                margin-left: 20px;
                font-size: 16px;
                transition: all 0.3s;
                &:hover {
                    color: $white;
                }
                &.active {
                    color: $white;
                }
                @media screen and (max-width: 768px) {
                    margin-right: 20px;
                    margin-left: 0px;
                }
            }
        }
        .close {
            svg {
                path {
                    fill: $seventh;
                    transition: all 0.2s;
                }
                &:hover {
                    path {
                        fill: $white;
                    }
                }
            }
            @media screen and (max-width: 250px) {
                width: 100%;
                margin-top: 21px;
                text-align: center;
            }
        }

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    .divider {
        height: 1px;
        width: 100%;
        border-radius: 10px;
        background-color: $seventh;
    }
    .categories-body {
        transition: all 0.3s;
        padding: 21px 21px 21px 21px;
        overflow: auto;
        max-height: 400px;
        height: 100%;
        overflow: auto;
        height: 100%;

        .category-container {
            .heading {
                color: $white;
                font-size: 16px;
            }
            .category {
                width: fit-content;
                margin-top: 15px;
                label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
                input[type="checkbox"] {
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                }
                .label:before {
                    content: "";
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 1px solid #303030;
                    padding: 8px;
                    border-radius: 5px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 10px;
                }

                input:checked {
                    + .label:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 8px;
                        left: 7px;
                        width: 5px;
                        height: 8px;
                        border: solid #f71137;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                    + .label::before {
                        background-color: $white;
                    }
                }
                .label {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $third;
                    // max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: relative;
                }
            }
        }

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $secondary;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $seventh;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $third;
        }
    }
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 21px;
}
