@import "../variables";
.collection-wrapper-layout {
  .create-wrapper {
    min-height: calc(100vh - 242px);
    display: flex;
    &.margin-top-reset {
      margin: 50px 0;
    }
  }
  .main-title {
    margin-bottom: 40px;
    @media screen and (max-width: 574px) {
      margin-bottom: 20px;
      
    }
  }
}


.collection-detail {
  .collection-link-list{
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 574px) {
    margin-top: 20px; 
  }
  li{
    display: inline-flex;
    align-items: center;
  
      &::after{
      display: inline;
      height: 80%;
      width: 1px;
      background-color: $ninth;
      margin: 0 10px;
      content: "";
      align-self: center;
    }
    &:last-child{
    
      &::after{
        display: none;
        }
    }
  }
}
.nav-tabs {
  background-color: $ninth;
  border-bottom: 0px;
  padding: 5px 5px;
  border-radius: 10px;
  .nav-link {
    font-size: 14px;
    color: $secondary;
    margin-right: 5px;
    border-radius: 5px;
    padding: 16px 30px;
    opacity: 1;
    &:hover {
      border-color: transparent;
    }
    @media screen and (max-width: 1199px) {
      padding: 10px 20px;
    }
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    color: $secondary;
  }
}
}


.overview-nft{
  .poster-wrapper{
    width: 100%;
    margin: 0px  0 20px;
    position: relative;

    @media screen and (max-width: 574px) {
      margin: 20px  0 100px;
    
    }
    
    .poster-img-wrapper{
      height: 200px;
      overflow: hidden;
      border: 1px solid $ninth;
      border-radius: 5px;
      .poster-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
    }
    .profile-img-wrapper{
      width: 160px;
      height: 160px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $ninth;
      position: absolute;
      bottom: -80px;
      left: 40px;
      .profile-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
 
  .overview-nft-inner-wrapper{
    display: flex;
    @media screen and (max-width: 574px) {
      width: 100%;     
    }
    
  }
  .block-1{
    width: 200px;
   
    // border: 1px solid $ninth;
    // border-radius: 5px;
    display: inline-flex;
    margin-right: 20px;
    @media screen and (max-width: 574px) {
      display: none;
      margin-right: 0px;
    }
  }
  .block-2{
    width: calc(100% - 220px);
    display: inline-flex;
    flex-direction: column;
    @media screen and (max-width: 574px) {
      width:100%;
    }
  }
  .create-min-form {
    z-index: 1;
    .form-input-mb {
      margin-bottom: 20px !important;
    }
    .form-control {
      color: $secondary!Important;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px !important;
      padding-right: 75px;
    }
    .character-counter {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      p{
        font-size: 12px;
        color: $forth;
        opacity: 0.5;
      }
      &.text-area {
        position: absolute;
        right: 20px;
        top: auto;
        bottom: 20px !important;
        transform: translate(0, 0%);
      }
    }
    textarea {
      &.form-control {
        padding-top: 20px;
        padding-bottom: 20px;
        height: 125px !important;
      }
    }

    .form-control:disabled,
  .form-control-no-border:disabled {
    background-color: $WhiteLight;
    border-color: $ninth;
  }
  }
  .feature-image{
    width: 100%;
    height: 200px;
    border: 1px solid $ninth;
    border-radius: 5px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .single-channel{
    .two-input{
      display: block;
      .form-input-mb{
        width: 100%;
        margin-bottom: 10px!important;
        &:first-child {
        margin-right: 0px;
        }

        &:nth-child(2n) {
          margin-left: 0px;
          }
        }
    }
  }
}

.two-input {
  display: flex;
  // align-items: center;
  .form-input-mb {
    margin-bottom: 20px !important;
    width: calc(50% - 10px);
    &:first-child {
      margin-right: 10px;
    }
    &:nth-child(2) {
      margin-left: 10px;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 20px !important;
    width: 100%;
    display: block;
    &:first-child {
      margin-right: 0px;
    }
    &:nth-child(2) {
      margin-left: 00px;
    }
    }


  }
  @media screen and (max-width: 991px) {
    display: block;
  }
}
.max-category-menu {
  .css-4ljt47-MenuList {
    max-height: 120px !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  .css-26l3qy-menu {
    max-height: 120px !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
.overview-nft{ .create-min-form{ 
.social-link-content-wrapper{
  .form-input-wrap-mb{
    margin-bottom: 0!important;
  }
}}}
.disable{
  .social-input{
  background-color: $WhiteLight;
  border-color: $ninth;
}
}
.social-input{
  display: flex;
  .social-icon{
    width:63px;
    height: 63px;
    min-width:63px;
    min-height: 63px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $WhiteLight;
    border: 1px solid $ninth;
    border-radius: 5px 0 0 5px !important;
    color: $forth;
  }
  .form-control{
    border-radius:0  5px  5px 0 !important;
    border-left: 0;
    color:$forth!important;
  }
  .input-wrapper-bl{
    border-radius:0  5px  5px 0 !important;
    border-left: 0;
    color:$forth!important;
    display: flex;
    border: 1px solid $ninth;
    align-items: center;
    padding: 0 20px;
    flex-grow: 1;
    border-left: 0;
    max-width: calc(100% - 63px);
}
.cy-bl{
  color: $forth;
  opacity: 0.5;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
input{
    border: 0;
    height: auto;
    padding: 0;
    padding-right: 0px!important;
    flex-grow: 1;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $forth;
      opacity: 0.5;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $forth;
      opacity: 0.5;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $forth;
  opacity: 0.5;
    }
}
}



@media (min-width: 991px) {
  .collection-confirmation-modal {
    max-width: 535px !important;
  
  }
}
@media (max-width: 990px) {
  .collection-confirmation-modal{
    max-width: 450px !important;
  }
}
@media (max-width: 575px) {
  .collection-confirmation-modal{
    max-width: 100% !important;
  }
}


// add nft -modal css start
.collection-add-nft-pop{
  .nft-list-wrapper{
    overflow: auto;
    max-height: 350px;
    .add-nft-checkbox-wrapper{
      margin-bottom: 20px!important;
    }
  }
}


.collection-list-tab{
  .detail-collection-wrapper {
     margin-bottom: 30px;
     .content-block{
       background-color: $WhiteLight;
     }
  }
}
.css-1insrsq-control{
min-height: 63px!Important;
}


.modal-backdrop {
  backdrop-filter: blur(50px) !important;
  -webkit-backdrop-filter: blur(50px);
  background: $ninth!important;
}

.modal-backdrop.show {
  opacity: 0.9!important;
}
