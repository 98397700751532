/** @format */

@import "../variables";
.profile-wrapper {
  .profile-banner {
    height: 240px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      background: rgba($color: #000000, $alpha: 0.2);
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .profile-detail-section {
    margin-top: -50px;
    margin-bottom: 70px;

    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  .profile-inner-wrapper {
    // background: #ffffff;
    padding: 0 0 30px 0;
    // border-radius: 20px;
    position: relative;
    display: flex;
    width: 100%;

    @media screen and (max-width: 767px) {
      padding: 0 0 15px 0;
    }
    .second-title-wrapper {
      display: inline-flex;
      flex-direction: column;
      width: calc(100% - 195px);
      padding-left: 30px;
      margin-top: 35px;
      padding-top: 20px;
      @media screen and (max-width: 767px) {
        width: calc(100% - 130px);
        padding-left: 15px;
      }
      .capsual-block {
        position: absolute;
        top: 37px;
      }
    }
    .img-wrapper {
      width: 172px;
      height: 172px;
      border-radius: 50%;
      border: 10px solid #090909;
      // margin: 0 auto;
      @media screen and (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sub-title {
      font-size: 30px;
      line-height: 36px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .menu {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  .item-counter-list {
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding-top: 50px;
    flex-wrap: wrap;
    justify-content: flex-end;
    @media screen and (max-width: 991px) {
      justify-content: flex-start;
      margin: 0 0 15px 0;
      padding-top: 0;
    }
    @media screen and (max-width: 767px) {
      padding-top: 10px;
      justify-content: flex-start;
    }
    li {
      display: inline-flex;
      margin-left: 50px;
      align-items: center;
      h5 {
        margin-left: 10px;
        font-weight: 400;
      }
      @media screen and (max-width: 1199px) {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      @media screen and (max-width: 991px) {
        margin-left: 0px;
        margin-right: 15px;
        &:last-child {
          margin-bottom: 15px;
        }
      }

      .semi-title {
        font-size: 26px;
        line-height: 33px;
        @media screen and (max-width: 1199px) {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
  .tab-container {
    margin-bottom: 40px;
    background: $fifth;
    border-radius: 10px;
    min-height: 60px;
    padding: 5px;
    position: relative;
    transition: all 0.25s;
    @media screen and (max-width: 991px) {
      overflow-x: scroll;
    }
    .btn {
      position: relative;
      z-index: 10;
      font-size: 14px;
      padding: 16px 30px;
      color: $third;
      transition: all 0.25s;

      &.active {
        // background: $white;
        color: $secondary;
        span {
          font-weight: 700;
          transition: all 0.6s;
        }
      }
      // &.active.btn1 ~ .glider {
      //   transform: translateX(0%);
      // }
      // &.active.btn2 ~ .glider {
      //   transform: translateX(100%);
      // }
      // &.active.btn3 ~ .glider {
      //   transform: translateX(200%);
      // }
      @media screen and (max-width: 991px) {
        min-width: 155px;
      }
    }
    .glider {
      position: absolute;
      content: " ";
      top: 5px;
      left: 5px;
      background: $white;
      width: 120px;
      height: 55px;
      border-radius: 5px;
      z-index: 1;
      transition: all 0.4s;
      animation-delay: 0;
      transform: translateX(0%);
    }
  }
  .detail-collection-wrapper {
    margin-bottom: 30px;
  }
}

.capsual-block {
  font-size: 12px;
  border-radius: 10px;
  padding: 0px 10px;
  width: fit-content;
}

.edit-bl {
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  &:hover {
    color: #f71137;
    border-color: #f71137;
  }
}
.profile-content-sm-wrapper {
  background-color: $fifth;
  border-radius: 5px;
  // opacity: 0.5;
  padding: 40px;
  .title {
    h4 {
      line-height: 22px;
      margin-bottom: 10px;
    }
  }
}
