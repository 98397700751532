/** @format */

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
$primary: #f71137;
$secondary: #090909;
$third: #929292;
$forth: #828282;
$fifth: #191919;
$sixth: #f7113778;
$seventh: #303030;
$eight: #34c77b;

$ninth: #dadce0;
$tenth: #7a7a7a;    
$eleven: #0d0f12;
$white: #ffffff;
$WhiteLight: #f8f8f8;
$green: #85d996;

$skeletonBaseColor: $secondary;
$skeletonHighlightColor: $fifth;

$primary_Font: "Outfit", sans-serif;

// override bootstrap colors
$theme-colors: (
    "primary": #f71137,
    "danger": #ff4136,
);
