@import "../variables";
.collectionblock {
  margin-bottom: 40px;
  .poster-img-wrapper {
    position: relative;
    .img-wrapper {
      height: 200px;
      border: 1px solid $ninth;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 60px;
      .poster-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    } .img-wrapper2 {
      height: 200px;
      overflow: hidden;
      margin-bottom: 60px;
      .poster-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .profile-img-wrapper {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: -40px;
      transform: translate(-50%, 0);
      left: 50%;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $ninth;
      .profile-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .content-block{
    padding: 0 20px;
    margin-bottom: 0;
    text-align: center;
   .cl-title{
     margin-bottom: 10px;
   }
    .content-prg{
      -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
  }
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}