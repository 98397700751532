@import "../variables";

.search-user-filter {
  height: 80px;
  transition: all 0.3s, visibility 0s;
  &.hide {
    height: 0;
    visibility: hidden;
  }
  .top-user-search {
    background-color: transparent !important;
    color: white !important;
    padding: 10px 35px 10px 20px !important;
    height: 45px !important;
    border-color: #303030 !important;
    margin: auto;
    display: block;
    width: 100%;
    padding-right: 60px;
  }
}
.search-btn {
  min-width: 82px;
}
.top-user {
  .top-user-wrapper {
    height: 270px;
    margin-bottom: 25px;
    border-radius: 10px;
    transition: all 0.5s;
    .img-wrapper {
      height: 110px;
      width: 100%;
      overflow: hidden;
      border-radius: 10px 10px 0px 0;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-info {
      border-radius: 10px;
      &.pb-50 {
        padding-bottom: 50px;
      }
      .user-block {
        margin-top: -50px;
        display: flex;
        text-align: center;
        justify-content: center;

        .user-img-wrapper {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 3px solid #ffffff;
          position: relative;
          transition: all ease 0.5s;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: all ease 0.5s;
          }
        }
        .active-bl {
          position: absolute;
          right: 9px;
          bottom: 8px;
          width: 12px;
          height: 12px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: block;
          content: " ";
          background-color: $eight;
        }
      }
      .capsual-block {
        background-color: $forth;
        line-height: 15px;
        line-height: 12px;
        padding: 3px 15px;
        border: 2px solid #ffffff;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 20px;
        display: flex;
        margin: 0 auto;
        margin-top: 10px;
        width: fit-content;
        align-self: center;
        margin-bottom: 5px;
        position: relative;
        overflow: visible;

        .animate-bl {
          font-weight: 300;
          font-size: 11px;
          line-height: 17px;
        }
        // &::before {
        //     position: absolute;
        //     top: -190px;
        //     opacity: 0;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     content: attr(data-title);
        //     color: #ffffff;
        //     transition: all ease 0.5s;
        //     width: 100%;
        //     text-align: center;
        // }
        // &:after {
        //     position: absolute;
        //     top: 50%;
        //     transform: translate(-50%, -50%);
        //     content: attr(data-title);
        //     left: 50%;
        //     color: #ffffff;
        //     transition: all ease 0.5s;
        //     width: 100%;
        //     text-align: center;
        // }
      }
      .username-block {
        padding-left: 30px;
        padding-right: 30px;
        h3 {
          max-width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .checkmark-block {
          font-size: 12px;
          line-height: 12px;
          margin-top: 2px;
          margin-left: 3px;
          color: $primary;
        }
      }
    }
    .nft-ct {
      margin-top: 0px;
      padding-bottom: 10px;
    }
    &:hover {
      // transform: scale(1.1);
      // height: 325px;
      // transition: all 0.5s;
      // .user-block {
      //   .user-img-wrapper {
      //     width: 115px;
      //     height: 115px;
      //   }
      // }
      // .capsual-block {
      //   &::before {
      //     opacity: 1;
      //     position: absolute;
      //     display: block;
      //     top: 50%;
      //   }
      //   &:after {
      //     top: 250px;
      //     left: 50%;
      //     transform: translate(-50%, -50%);
      //     transition: all ease 0.5s;
      //     opacity: 0;
      //   }
      //   .animate-bl {
      //     opacity: 0;
      //     transition: ease-out 5s;
      //     // transition: all ease 2s;
      //     // transition: all ease 0.5s;
      //   }
      // }
      .content-info {
        .user-block {
          .user-img-wrapper {
            width: 110px;
            height: 110px;
            transition: all ease 0.5s;
            img {
              transition: all ease 0.5s;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
  }
}
.tops-user-search {
  color: rgb(247, 17, 55);
  position: absolute;
  top: 17px;
  right: 15px;
}
/*Top User section end*/

.top-user-section {
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 992px) {
    position: relative;
  }
}
