@import "../variables";

.select-filter {
  div {
    color: #fff;
  }
  input {
    color: #fff;
  }
  .react-tag-input__tag {
    background: rgba($color: $white, $alpha: 0.5);
    .react-tag-input__tag__content {
      color: $ninth;
    }
    .react-tag-input__tag__remove {
      background: rgba($color: $white, $alpha: 0.14);
      // &:hover {
      //   background: rgba($color: $primary, $alpha: 0.5);
      //   &::before,
      //   &::after {
      //     background-color: rgba($color: $primary, $alpha: 1);
      //   }
      // }
      &::before,
      &::after {
        background-color: $ninth;
      }
    }
  }
}

.explore-collection-wrapper {
  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      .services-list {
        display: flex;
        flex-wrap: wrap;
      }
      .seond-wrap {
        flex: 1;
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100px;
      }
    }
  }
  .services-list {
    .filter-btn {
      margin: 5px 0;
      margin-right: 10px;
    }
  }
  .filter-btn {
    padding: 10px 20px;
    border: 1px solid #303030;
    color: $white;
    border-radius: 5px;
    background-color: transparent !important;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    line-height: 18px;
    &:hover {
      // background-color: #ffffff !important;
      color: $primary;
    }
    &.active {
      background-color: #ffffff !important;
      color: $primary;
    }
  }
  .detail-collection-wrapper {
    margin-bottom: 30px;
  }
  &.search {
    .detail-collection-wrapper {
      margin-bottom: 0px;
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
  }
}

.pdf {
  transition: all 0.3s;
  p {
    margin-bottom: -25px;
  }
  &:hover {
    p {
      visibility: visible !important;
      transition: all 0.3s;
    }
  }
}
.video-itself {
  transition: all 0.1s;
  svg {
    visibility: hidden;
    cursor: pointer;
  }
  &:hover svg {
    visibility: visible;
  }

  @media screen and (max-width: 600px) {
    svg {
      visibility: visible !important;
    }
  }
}
.filter-select-wrapper {
  margin-top: 40px;
  overflow: hidden;
  transition: height 0.3s;
  // chain type bg
  // tags bg
  // NFT type bg
  .chain-type__control,
  .NFT-type__control,
  .react-tag-input {
    background-color: $secondary !important;
    border-color: #303030 !important;
  }

  .react-tag-input {
    height: 63px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #303030;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 15px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: white;
    }
  }

  .css-6j8wv5-Input {
    color: #ffffff !important;
  }
  .css-1pahdxg-control {
    background-color: $secondary !important;
  }
  .css-1pahdxg-control:hover {
    border-color: #ffffff !important;
    background-color: $secondary !important;
  }
  .css-qc6sy-singleValue {
    border-color: #ffffff !important;
  }
  .css-qc6sy-singleValue,
  .css-1gtu0rj-indicatorContainer {
    color: #ffffff !important;
  }
}
.collection-wrap {
  margin-top: 30px;
}

.collection-with-link {
  .custom-link {
    font-size: 18px;
    line-height: 26px;
  }
}

.css-4ljt47-MenuList {
  max-height: unset !important;
  overflow-y: unset !important;
  box-sizing: border-box;
}
.css-1n7v3ny-option {
  background: #191919 !important;
}
.css-26l3qy-menu {
  background-color: #090909 !important;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group {
  display: block;
  margin-bottom: 0px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #303030;
  padding: 8px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background-color: #ffffff;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 7px;
  width: 5px;
  height: 8px;
  border: solid #f71137;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

// custom select style
.chain-type__indicator,
.NFT-type__indicator,
.tags__type__indicator,
.tags__clear-indicator {
  cursor: pointer;
}

.chain-type__value-container,
.NFT-type__value-container {
  overflow: auto !important;
  height: 60px;
}
.chain-type__multi-value,
.NFT-type__multi-value {
  background-color: rgba($color: #ffffff, $alpha: 0.1) !important;
  border: 1px solid rgba($color: #ffffff, $alpha: 0.3) !important;
  .chain-type__multi-value__remove {
    color: rgba($color: #fff, $alpha: 0.5);
  }
  .NFT-type__multi-value__remove {
    color: rgba($color: #fff, $alpha: 0.5);
  }
  .chain-logo {
    height: 14px;
  }
  // .yourImage {
  //   -webkit-filter: invert(100%); /* Safari/Chrome */
  //   filter: invert(100%);
  // }
}

.chain-type__single-value {
  @extend .chain-type__multi-value;
  border: 0px !important;
  background: transparent !important;
  .chain-logo {
    height: 20px !important;
  }
}

// nft type
.NFT-type__multi-value {
  .nft-type-image {
    height: 20px;
  }
  p {
    display: none;
  }
}

.NFT-type__single-value {
  @extend .NFT-type__multi-value;
}

.gofullpageicon {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #ffffff;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50px;
  &.logged-in {
    right: 75px !important;
  }
}

// go full screen
/* webkit requires explicit width, height = 100% of sceeen */
/* webkit also takes margin into account in full screen also - so margin should be removed (otherwise black areas will be seen) */

#element:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  object-fit: contain !important;
}

#element:-moz-full-screen {
  margin: 0 !important;
  object-fit: contain !important;
}
:not(:root):fullscreen {
  object-fit: contain !important;
}

#element:-ms-fullscreen {
  margin: 0 !important;
  object-fit: contain !important;
}

/* W3C proposal that will eventually come in all browsers */
#element:fullscreen {
  margin: 0 !important;
  object-fit: contain !important;
}

/* empty calss for the css navigation in editor, do not remove it */
.filter-select-wrapper {
  height: 0px;
  margin-top: 0px;
  transition: height 0.3s;
}
.filter-select-wrapper .react-tag-input {
  overflow-x: hidden;
}
.filter-select-wrapper .react-tag-input__input {
  color: $white;
}
.animatedfilters {
  margin-top: 40px;
  height: 150px;
  overflow: visible;
  @media screen and (max-width: 767px) {
    height: 240px;
    margin-top: 0px;
  }
  @media screen and (max-width: 577px) {
    height: 350px;
    margin-top: 0px;
  }
}
.animatedinvoice {
  margin-top: 40px;
  height: 90px;
  overflow: visible;
  @media screen and (max-width: 767px) {
    height: 180px;
    margin-top: 0px;
  }
  @media screen and (max-width: 577px) {
    height: 290px;
    margin-top: 0px;
  }
}
/* react transitions effects */

/* empty calss for the css navigation in editor, do not remove it */
.animatedListItem {
}

.animatedListItem-enter {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}
.animatedListItem-enter-active {
  opacity: 1;
  transform-origin: top;
  transform: scaleY(1);
  transition: all 200ms ease-out;
}
.animatedListItem-exit {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
}
.animatedListItem-exit-active {
  transform-origin: top;
  opacity: 0;
  transform: scaleY(0);
  transition: all 200ms ease-in;
}

/* react transitions effects */

/* empty calss for the css navigation in editor, do not remove it */
.animatedCategoryButtons {
}

.animatedCategoryButtons-enter {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: left;
}
.animatedCategoryButtons-enter-active {
  opacity: 1;
  transform-origin: left;
  transform: scaleX(1);
  transition: all 400ms;
}

.animatedCategoryButtons-exit {
  opacity: 1;
  transform: scaleX(1);
  transform-origin: left;
}
.animatedCategoryButtons-exit-active {
  transform-origin: left;
  opacity: 0;
  transform: scaleX(0);
  transition: all 200ms ease-in;
}

// zoom in cards
.zoomInCard {
}

.zoomInCard-enter {
  opacity: 0;
  transform: scale(0.5);
}
.zoomInCard-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 200ms;
}
.zoomInCard-exit {
  opacity: 1;
  transform: scale(1);
}
.zoomInCard-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}

.draft-date {
  font-weight: normal;
  margin-top: 30px;
  color: #828282;
  margin-bottom: 0px;
}

.text-normal {
  font-weight: normal;
}

.dot-menu {
  font-size: 16px;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
  display: flex;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50px;
  flex-direction: column;
  width: 45px;
  height: 45px;
  overflow: hidden;
  &.active {
    width: 110px;
    height: 100%;
    border-radius: 10px;
    transition: all ease 0.2s;
    overflow: visible;
    padding-bottom: 10px;
    background-color: #ffffff;
    .dot-menu-link {
      width: 45px;
      justify-content: center;
    }
  }
  .dot-link {
    padding: 5px 15px;
    font-size: 13px;
    line-height: 20px;
    color: #828282;
    &:first-child {
      padding: 0px 15px 0;
    }
  }
}
.dot-menu-link {
  font-size: 16px;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
  cursor: pointer;
  min-height: 45px;
  min-width: 45px;
  width: 45px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nft-card-action-menu {
  position: absolute;
  top: 15px;
  left: 15px;

  &.hidden {
    display: none;
  }
}
