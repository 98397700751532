@import "../variables";

#metadata {
    max-width: 733px;
    .content {
        padding: 0 0 40px 0;
        margin-top: 40px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
            padding: 0 0 20px 0;
            margin-top: 20px;
        }
    }
    .metaData-heigth {
        max-height: 63px;
        width: 280px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
            var(--unnamed-line-spacing-24) var(--unnamed-font-family-outfit);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal normal 14px/24px Outfit;
        letter-spacing: 0px;
        color: $secondary;
        font-weight: 700;
    }
    .meta-container {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        // .input-container {
        //     width: calc(50% - 8px);
        //     .form-control {
        //         width: 100%;
        //         max-width: 100%;
        //     }
        //     &:first-child{
        //         margin-right: 8px;

        //     }
        //     &:nth-child(2){
        //         margin-left: 8px;

        //     }
        // }
        @media screen and (max-width: 767px) {
            // background: rgba($color: #000000, $alpha: 0.2);
            // padding: 10px 0px;
            // justify-content: center;
            // margin-bottom: 20px;
            // border-top-left-radius: 5px;
            // margin-left: 0 !important;
            // margin-right: 0 !important;

            .input-container {
                // padding-left: 10px;
                // padding-right: 10px;
                width: 100%;
                &:first-child {
                    margin-right: 0px;
                }
                &:nth-child(2) {
                    margin-left: 0px;
                }
                .form-control {
                    width: 100% !important;
                }
            }
        }
    }
    .metaData-close-btn {
        width: 63px !important;
        height: 63px !important;
        border: 1px solid $primary;
        background-color: transparent;
        color: $forth;
        border-color: $ninth;
        &:hover {
            color: white;
            border: 1px solid $primary;
            background-color: $primary;
        }

        @media screen and (max-width: 768px) {
            background: rgba($color: #fff, $alpha: 1);
        }
    }

    .hover-stop {
        background-color: $primary;
        color: #ffffff;
        // width: 119px;
    }
    .metaData-overFollow {
        max-height: 400px !important;
        overflow-x: hidden;
        overflow-y: auto;
        margin-right: -30px;
        padding-right: 30px;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $secondary;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $seventh;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $third;
        }

        @media screen and (max-width: 767px) {
            margin-right: -10px;
            padding-right: 10px;
        }
    }

    .metadata-error {
        color: $primary;
        text-align: left;
    }
}
