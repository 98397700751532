@import "../variables";
.create-custom-nft-wrapper {
  margin-top: 50px;
}
.main-title {
  margin-bottom: 30px;
  .count {
    font-size: 26px;
    color: $forth;
  }
}
.tab {
  height: calc(100vh - 417px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  @media screen and (max-width: 991px) {
    height: auto;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

/*stepper link block start*/

.list-step {
  display: flex;
}

.stepper-link-wrapper {
  height: 75px;
  margin-bottom: 50px;
}
.stepsblock {
  margin: 0px 0 0px 0;
  counter-reset: step;
  li {
    list-style-type: none;
    width: 33.33%;
    float: left;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    text-align: center;
    color: $forth;
    .dot-block-inner {
      width: 12px;
      height: 12px;
      content: "";
      line-height: 30px;
      border: 2px solid #dadce0;
      background-color: #dadce0;
      display: block;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.8s;
    }
    .dot-outer {
      width: 46px;
      height: 46px;
      content: "";
      line-height: 30px;
      display: flex;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      transition: all 0.8s;
      align-items: center;
      justify-content: center;
    }
    .dot-block {
      width: 26px;
      height: 26px;
      content: "";
      line-height: 30px;
      background-color: #ffffff;
      display: flex;
      text-align: center;
      border-radius: 50%;
      transition: all 0.8s;
      box-shadow: 0px 0px 40px #00000029;
      align-items: center;
      justify-content: center;
    }
    &:after {
      width: 100%;
      height: 2px;
      content: "";
      position: absolute;
      background-color: #dadce0;
      top: 23px;
      left: -50%;
      z-index: -1;
      transition: all 0.8s;
    }
    &:first-child {
      &:after {
        content: none;
      }
    }
    &.active {
      color: $secondary;
      &:after {
        background-color: $forth;
      }
      .dot-block {
        svg {
          color: #ffffff;
        }
        background-color: $forth;
      }
      .dot-outer {
        background-color: rgba(9, 9, 9, 0.1);
      }
    }
  }
}

.drag-wrapper {
  input {
    display: none;
  }
  .content {
    line-height: 18px;
    font-size: 14px;
    .small {
      font-size: 13px;
    }
  }
  .click-curser {
    cursor: pointer;
    color: $primary;
  }
}
.drop-box {
  border: 1px dashed #dadce0;
  height: 125px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.disabled {
    background-color: #f8f8f8;
  }
}
.drop-img-wrapper {
  height: 80% !important;
  border-radius: 5px;
}
.footer-step {
  border-top: 1px solid #dadce0;
  padding-top: 20px;
  margin-top: auto;
}
.title-bl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ct-tl-link {
  font-size: 14px;
  color: $primary;
  text-decoration: none;
  font-weight: 700;
}
.create-min-form {
  // height: 47vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    background: lightgray;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 255, 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
  }
}
.nft-attribute-form {
  .form-input-mb-10 {
    margin-bottom: 5px !important;
  }
  .two-input {
    display: flex;
    align-items: center;
    .form-input-mb {
      margin-bottom: 10px !important;
      width: calc(50% - 5px);
      &:first-child {
        margin-right: 5px;
      }
      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: lightgray;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 255, 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
  }
  .css-26l3qy-menu {
    background-color: #ffffff !important;
  }
  .css-1n7v3ny-option {
    color: #ffffff !important;
  }
}
.pdf-wrapper {
  border: 1px solid #dadce0;
  height: 100px;
  border-radius: 5px;
}
.input-file-max-content {
  max-width: calc(100% - 80px);
}
.etc-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #090909;
  border: 1px solid #dadce0;
  padding: 0 20px;
  height: 63px;
  border-radius: 5px !important;
  pointer-events: visible;
  &:focus,
  &.active {
    border-color: $primary !important;
  }
}

.wareenty-card {
  margin-top: 0px;
  position: sticky;
  margin-bottom: 15px;
  top: 0;
  min-height: 200px;
}

.review-nft {
  .wareenty-card {
    margin-top: 0px;
    position: relative;
  }
  .sticky-warrenty-card {
    margin-top: 0px;
    position: sticky;
  }
  .form-control:disabled,
  .form-control-no-border:disabled {
    background-color: #f8f8f8;
    border-color: #dadce0;
  }
}

.custom-animation {
  animation: opacity-animation 2s;
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-animation2 {
  animation: opacity-animation2 0.5s;
}

@keyframes opacity-animation2 {
  0% {
    opacity: 0;
    margin-top: -80px;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.custome-nft-modal {
  position: relative;
  max-width: 452px;
  .c-img {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .c-p {
    font-size: 13.9px;
    margin-bottom: 40px;
  }
  // button {
  //   width: 46%;
  // }
}
.css-1pahdxg-control {
  border-color: rgb(218, 220, 224)!important;
}
.error-append {
  .css-1s2u09g-control {
    border-color: #f51138 !important;
    border: 1px solid;
  }
  .css-1pahdxg-control {
    border-color: #f51138 !important;
    &:hover {
      border-color: #f51138 !important;
    }
  }
}


.custom-type {
  .css-1s2u09g-control {
    cursor: pointer !important;
  }
  .css-26l3qy-menu {
    div {
      cursor: pointer !important;
    }
  }
}

@media screen and (max-width: 767px) {
 .footer-step{
  margin-bottom: 80px;
 }
}