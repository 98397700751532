@import "../variables";

.check-credit {
  gap: 10px;
  margin-bottom: 20px;
  width: fit-content;
  .custom-checkbox {
    height: 26px;
    width: 30px;
    @media screen and (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
    .custom-checkbox-input {
      display: none;
      &:checked ~ .custom-checkbox-box {
        border-color: $primary;
        span {
          background-color: #f71137;
          border-radius: 5px;
          height: 100%;
          width: 100%;
          display: inline-block;
          @media screen and (max-width: 767px) {
            transform: scale(0.85);
          }
        }
      }
    }
    .custom-checkbox-box {
      width: 24px;
      height: 24px;
      background-color: $white;
      display: inline-block;
      border: 1px solid $ninth;
      border-radius: 5px;
      padding: 3px;
      top: 0;
      @media screen and (max-width: 767px) {
        width: 20px;
        height: 20px;
        padding: 1px;
      }
    }
    & + p {
      font-size: 18px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
