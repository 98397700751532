@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0 !important;
  font-size: 14px;
  line-height: 22px;
  font-family: $primary_Font !important;
  background-color: $secondary;
  font-weight: normal;

  background-image: url('../assets/images/main-bg-new.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;

  @media screen and (max-width: 1199px) {
    background-size: cover;
  }

  @media screen and (max-width: 767px) {
    background-size: cover;
  }

  &.light-theme {
    background-image: none;
    background-color: #ffffff;

    .header-wrapper {
      .navbar-light {
        .navbar-brand {
          color: $secondary;
        }

        .navbar-toggler {
          color: $secondary;
        }

        .user-dropdown {
          .user-link {
            svg {
              color: $secondary;
            }
          }
        }
      }

      .navbar-nav {
        .nav-link:not(.text-primary) {
          color: $secondary;
          transition: all ease 0.2s;

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }

      .navbar-collapse {
        border-left: 1px solid $ninth;
      }
    }

    .footer-wrapper {
      background: transparent;

      .footer-first-block {
        display: none;
      }

      .footer-second-block {
        border-top: 1px solid $ninth;
      }
    }
  }

  // hide scrollbar if modal open
  &.modal-open {
    overflow: hidden;
  }

  .title-lr {
    font-size: 36px;
    line-height: 40px;

    @media screen and (max-width: 1399px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: 24px;
    }
  }
}

html.modal-open {
  overflow: hidden;

  body {
    padding-right: 17px !important;
  }
}

.invalid-feedback {
  color: $primary !important;
}

.nft-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.nft-card-action-container {
  position: absolute;
  top: 15px;
  right: 15px;

  .favorite-ic-block {
    cursor: pointer;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    color: black;
  }

  &.hidden {
    display: none;
  }

  &>* {
    margin-bottom: 15px;
  }
}

.dropdown-item {
  padding: 0.25rem 0.3rem;
}

// override bootstrap buttons shadow
.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

// hover global
a:hover {
  color: inherit;
  opacity: 0.9;
}

.no-hover {
  &:hover {
    color: $primary;
  }
}

.btn-secondary {
  color: $white;

  &:hover {
    color: $white;
  }
}

// .backgroud-block {
//   height: 100vh;
//   background-image: url("../../images/main-bg.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: fixed top;
//   // position: fixed;
// }
.disable {
  color: $ninth;
  border-color: $WhiteLight;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $WhiteLight;
    color: $ninth;
    border-color: $WhiteLight;
  }

  pointer-events: none;
  opacity: 0.7;

  .card-input-check {
    background: $WhiteLight;
    cursor: not-allowed;
  }
}

.disable-with-bg {
  @extend .disable;
  background: $ninth;
}

.cursor {
  cursor: pointer !important;
}

dl,
ol,
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.h1,
h1 {
  font-size: 60px;
  line-height: 80px;
}

.h2,
h2 {
  font-size: 36px;
  line-height: 80px;
}

.h3,
h3 {
  font-size: 20px;
  line-height: 26px;
}

.h4,
h4 {
  font-size: 16px;
  line-height: 30px;
}

.h5,
h5 {
  font-size: 14px;
  line-height: 24px;
}

// .h6,
// h6 {
//   font-size: 16px;
//   line-height: 20px;
// }
.f-26 {
  font-size: 26px;
}

p,
.p {
  font-size: 14px;
  line-height: 24px;
}

// .small {
//   font-size: 12px;
//   line-height: 15px;
// }
// colors custon classes
.c-tx-primary {
  color: $primary;
}

.c-tx-secondary {
  color: $secondary;
}

.c-tx-third {
  color: $third;
}

.c-tx-forth {
  color: $forth;
}

.c-tx-fifth {
  color: $fifth;
}

.c-tx-sixth {
  color: $sixth;
}

.c-tx-seventh {
  color: $seventh;
}

.c-tx-eight {
  color: $eight;
}

.c-tx-ninth {
  color: $ninth;
}

.c-tx-tenth {
  color: $tenth;
}

.c-tx-eleven {
  color: $eleven;
}

.c-tx-green {
  color: $green;
}

// background colors custon classes
.c-bg-primary {
  background-color: $primary;
}

.c-bg-secondary {
  background-color: $secondary;
}

.c-bg-third {
  background-color: $third;
}

.c-bg-forth {
  background-color: $forth;
}

.c-bg-fifth {
  background-color: $fifth;
}

.c-bg-sixth {
  background-color: $sixth;
}

.c-bg-seventh {
  background-color: $seventh;
}

.c-bg-eight {
  background-color: $eight;
}

.c-bg-ninth {
  background-color: $ninth;
}

.c-bg-tenth {
  background-color: $tenth;
}

.c-bg-eleven {
  background-color: $eleven;
}

.c-bg-green {
  background-color: $green;
}

.c-bg-lightgray {
  background-color: $WhiteLight;
}

.bg-skeleton-base {
  background-color: $skeletonBaseColor;
}

.bg-skeleton-highlight {
  background-color: $skeletonHighlightColor;
}

.bg-skeleton-dark {
  background-color: $fifth;
}

// skeleton  colors
// .react-loading-skeleton {
//     --base-color: #191919 !important;
//     --highlight-color: #090909 !important;
// }

// font-weight custom classes
.f-100 {
  font-weight: 100 !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 400 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-900 {
  font-weight: 900 !important;
}

// line-height classes

.lh-32 {
  line-height: 32px;
}

.lh-26 {
  line-height: 26px;
}

.lh-24 {
  line-height: 24px;
}

.lh-30 {
  line-height: 30px;
}

.lh-16 {
  line-height: 16px;
}

.cy-15 {
  padding: 15px 0;
}

.ct-15 {
  padding-top: 15px;
}

.cb-15 {
  padding-bottom: 15px;
}

.cy-20 {
  padding: 20px 0;
}

.cy-50 {
  padding: 50px 0;

  @media screen and (max-width: 1399px) {
    padding-bottom: 0;
    padding-top: 25px;
  }
}

.ct-20 {
  padding-top: 20px;
}

.cb-20 {
  padding-bottom: 20px;
}

.cy-30 {
  padding: 30px 0;
}

.ct-30 {
  padding-top: 30px;
}

.cb-30 {
  padding-bottom: 30px;
}

.cy-90 {
  padding: 90px 0;
}

.ct-90 {
  padding-top: 90px;
}

.cb-90 {
  padding-bottom: 90px;
}

.c-gap-20 {
  gap: 20px;
}

.c-my-90 {
  margin: 90px 0;
}

.c-mt-90 {
  margin-top: 90px;
}

.c-mb-90 {
  margin-bottom: 90px;
}

.cy-100 {
  padding: 100px 0;
}

.ct-100 {
  padding-top: 100px;
}

.cb-100 {
  padding-bottom: 100px;
}

.c-my-100 {
  margin: 100px 0;
}

.c-mt-100 {
  margin-top: 100px;
}

.c-mb-100 {
  margin-bottom: 100px;
}

.c-my-30 {
  margin: 30px 0;
}

.c-ml-10 {
  margin-left: 10px;
}

.c-ml-5 {
  margin-left: 5px;
}

.c-mr-20 {
  margin-right: 20px;
}

.c-mt-30 {
  margin-top: 30px;
}

.c-mb-30 {
  margin-bottom: 30px;
}

.c-mb-40 {
  margin-bottom: 40px;
}

.c-mt-40 {
  margin-top: 40px;
}

.c-mx-30 {
  max-width: 250px;
}

.c-br-2 {
  border-radius: 3px;
}

.c-br-3 {
  border-radius: 10px;
}

.w-35 {
  width: 35%;
}

.border-none {
  border: none;
}

.background-transparent {
  background: transparent;
}

.section-margin-y {
  margin: 100px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-mint-div {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
}

.section-margin-bottom {
  margin-bottom: 100px;
}

.section-margin-top {
  margin-top: 100px;
}

/*background with transparent*/
.dark-block {
  background-color: $secondary;
  border-radius: 20px;
  background: transparent linear-gradient(90deg, #090909 0%, #19191900 100%);
}

.dark-block-2 {
  border-radius: 20px;
  background: transparent linear-gradient(269deg, #090909 0%, #19191900 100%);
}

.border-mk {
  .modal-content {
    border-radius: 20px;
    border: 0px none;
  }
}

.custom-primary {
  background-color: $primary;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  border: 1px solid $primary;
  outline: none;
  box-shadow: none;
  padding: 20px 40px;
  border-radius: 5px;
  transition: all ease 0.5s;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #ffffff;
    border-color: $primary;
    background-color: $primary;
    box-shadow: none;
    outline: none;
    transition: all ease 0.5s;
  }

  &-outline {
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    border: 1px solid $primary;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    padding: 20px 40px;
    border-radius: 5px;
    transition: all ease 0.5s;

    // text-transform: capitalize;
    &.dark-text {
      color: $secondary;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $primary;
      outline: none;
      border-color: $primary;
      box-shadow: none !important;

      &.dark-text {
        color: #ffffff;
      }
    }
  }
}

.custom-grey {
  background-color: #ffffff;
  font-size: 18px;
  line-height: 20px;
  color: $forth;
  border: 1px solid #dadce0;
  outline: none;
  box-shadow: none;
  padding: 20px 40px;
  border-radius: 5px;
  transition: all ease 0.5s;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #ffffff;
    border-color: $primary;
    background-color: $primary;
    box-shadow: none;
    outline: none;
    transition: all ease 0.5s;
  }
}

.custom-link {
  color: $primary;
  transition: all ease 0.5s;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    transition: all ease 0.5s;
  }

  &.darlk {

    &:hover,
    &:focus,
    &:active {
      color: $secondary;
    }
  }

  &.none {

    &:hover,
    &:focus,
    &:active {
      color: $primary !important;
    }
  }
}

.custom-link-blk {
  color: $secondary;
  transition: all ease 0.5s;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}

.custom-link-grey {
  color: $forth;
  transition: all ease 0.5s;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: $primary;
    transition: all ease 0.5s;
  }
}

.form-label {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.form-control,
.form-select {
  font-size: 18px;
  line-height: 36px;
  padding: 0 20px;
  height: 63px;
  border-radius: 10px;
  border: 1px solid $ninth;
  color: $secondary;
  box-shadow: none !important;
  outline: none !important;

  &:focus {
    border-color: $forth;
  }
}

.react-datepicker-wrapper {
  input {
    padding: 0 20px;
    height: 63px;
    border-radius: 5px;
    border: 1px solid $ninth;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    color: #090909;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px !important;
    padding-right: 75px;

    background-image: url('../assets/images/calendar-days-solid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center right 15px;

    &:focus {
      border-color: $forth;
    }

    background-image: url('../assets/images/calendar-days-solid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center right 15px;
  }
}

.form-control-no-border {
  @extend .form-control;
  border: none !important;
}

textarea {
  &.form-control {
    height: 100px !important;
    resize: none;
  }
}

.border-red {
  border: 1px solid !important;
  border-color: #dc3545 !important;
  border-radius: 5px;
}

.text-overflow-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// constainer full width in responsive

@media screen and (max-width: 1399px) {
  .custom-primary {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 25px;

    &-outline {
      font-size: 14px;
      line-height: 16px;
      padding: 15px 25px;
    }
  }

  .custom-grey {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 25px;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 100%;
  }

  .h1,
  h1 {
    font-size: 45px;
    line-height: 55px;
  }

  .h2,
  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .f-26 {
    font-size: 18px;
  }

  .h3,
  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .lh-32 {
    line-height: 24px;
  }

  .h4,
  h4 {
    font-size: 14px;
    line-height: 22px;
  }

  .h5,
  h5 {
    font-size: 14px;
    line-height: 20px;
  }

  .h6,
  h6 {
    font-size: 13px;
    line-height: 18px;
  }

  p,
  .p {
    font-size: 12px;
    line-height: 18px;
  }

  .custom-primary {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 25px;

    &-outline {
      font-size: 14px;
      line-height: 16px;
      padding: 15px 25px;
    }
  }

  .custom-grey {
    font-size: 14px;
    line-height: 16px;
    padding: 15px 25px;
  }
}

@media screen and (max-width: 767px) {
  .cy-90 {
    padding: 45px 0;
  }

  .ct-90 {
    padding-top: 45px;
  }

  .cb-90 {
    padding-bottom: 45px;
  }

  .c-my-90 {
    margin: 45px 0;
  }

  .c-mt-90 {
    margin-top: 45px;
  }

  .c-mb-90 {
    margin-bottom: 45px;
  }

  .cy-100 {
    padding: 45px 0;
  }

  .ct-100 {
    padding-top: 45px;
  }

  .cb-100 {
    padding-bottom: 45px;
  }

  .c-my-100 {
    margin: 45px 0;
  }

  .c-mt-100 {
    margin-top: 45px;
  }

  .c-mb-100 {
    margin-bottom: 45px;
  }

  .c-my-30 {
    margin: 15px 0;
  }

  .c-mt-30 {
    margin-top: 15px;
  }

  .c-mb-30 {
    margin-bottom: 15px;
  }

  .c-mb-40 {
    margin-bottom: 20px;
  }

  .h1,
  h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .section-margin-y {
    margin: 30px 0 45px 0;
  }
}

.section-title {
  line-height: 36px !important;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.sub-title {
  margin-top: 50px;
  margin-bottom: 30px;
}

.badge-explorer-card {
  font-size: 12px;
  color: $secondary;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: white;
  border-radius: 10px;

  &:after {
    content: '';
    background-color: white;
    filter: blur(3px);
    width: 100%;
    height: 100%;
  }
}

// full page media modal
.full-page-backdrop {
  background: $secondary;
  opacity: 1 !important;
}

.full-page-media-modal {
  &.modal-dialog {
    max-width: 100%;
  }

  .close-button {
    right: 50%;
    top: 0px;
    z-index: 200;
  }

  .modal-content {
    background: transparent !important;
    border: 0px !important;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    min-height: 60vh;

    .modal-media-content {
      min-height: 60vh;
    }

    .video-itself {
      svg {
        visibility: visible;
      }
    }

    video,
    img {
      object-fit: contain !important;
      width: 100% !important;
      height: 100% !important;
      max-height: 80vh;
      padding: 0 !important;
      margin: 0 !important;
    }

    .badge-explorer-card {
      display: none;
    }
  }
}

// .spinner-border {
//   border: 0.25em solid #707070 !important;
//   border-right-color: transparent !important;
// }

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.bg-dark {
  background-color: $fifth;
}

.animated-background {
  animation-duration: 2.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  // background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background: linear-gradient(to right,
      #292929 8%,
      #2c2c2b 18%,
      #2c2c2b 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.text-line {
  height: 25px;
  //  background: #F6F6F6;
  margin: 4px 0;
  @extend .animated-background;
}

.image-loader {
  @extend .animated-background;
}

.image-loader-pulse {
  @extend .animated-background;
  animation: pulse;
}

.image-loader-light {
  @extend .animated-background;
  background: linear-gradient(90deg,
      $WhiteLight 8%,
      $white 18%,
      $WhiteLight 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  border-radius: 10px;
}

.ct-18-link {
  font-size: 18px;
  line-height: 24px;

  @media screen and (max-width: 1199px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.btn-customer {
  font-size: 18px;
  line-height: 20px;
  color: #888484;
  border: 1px solid #e1e1e1;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  padding: 20px 40px;
  border-radius: 5px;
  transition: all ease 0.5;

  &:hover {
    color: #888484 !important;
  }
}

.btn-red {
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  border: 1px solid #f71137;
  outline: none;
  box-shadow: none;
  background-color: #f71137;
  padding: 20px 40px;
  border-radius: 5px;
  transition: all ease 0.5;

  &:hover {
    color: #fff !important;
  }
}

.text-custom {
  color: #fff;
  font-weight: 700;
  font-size: 14px;

  .chain-type__single-value {
    color: #090909;
  }
}

.cursor-select {
  cursor: pointer;

  div {
    cursor: pointer;
  }

  .category-type__input {
    // font-size: 18px !important;
    // line-height: 36px !important;
    font-weight: 400 !important;
  }

  .category-type__placeholder {
    @extend .category-type__input;
    font-size: 14px !important;
  }

  .category-type__value-container {
    padding-left: 20px !important;
  }

  .category-type__dropdown-indicator {
    padding-right: 20px !important;
  }
}

.min-heigth {
  min-height: 13.8vh;
}

.btn-hover {
  &:hover {
    background-color: #fff !important;
    color: #f71137 !important;
  }
}

.info-icon {
  color: rgb(247, 17, 55);
  border: 1.5px solid rgb(247, 17, 55);
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.draft-btn {
  width: 100%;
  height: 50px;
  font-size: 18px;
  line-height: 20px;
  color: #828282;
  border: 1px solid #dadce0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  transition: all ease 0.5s;

  &:hover {
    background-color: $primary;
    border: 1px solid $primary;
  }
}

// add this class to the button on which we want same styling overall the project.
.global-button {
  @extend .draft-btn;

  &.fill-primary {
    color: $white;
    background: $primary;
  }

  &.fill-secondary {
    border: 1px solid #dadce0;
    box-shadow: none;
    color: #828282;
    // height: 50px;
    line-height: 20px;
    outline: none;
    // padding: 10px;
    width: 100%;

    &:hover {
      color: $white;
    }
  }
}

.dark-text {
  color: $secondary;
}

.draft-row {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 0;
  margin-top: 30px;
}

// come in from bottom animation
.come-in {
  transform: translateY(300px);
  animation: come-in 0.8s ease forwards;
}

.come-in:nth-child(odd) {
  animation-duration: 0.6s;
  /* So they look staggered */
}

@keyframes come-in {
  to {
    transform: translateY(0);
  }
}

.browser-click {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 3;
  background-color: rgb(9, 9, 9, 0.7);
  transition: all 0.5s;

  span {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    z-index: 30;
  }
}

// animations
.pulse {
  animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background: $ninth;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}

// // modal animation
// .modal.fade .modal-dialog {
//     transition: all 0s ease-in-out !important;
//     transform: translate(0px, 0px) scale(1) !important;
//     opacity: 1 !important;
//     transition-delay: 0 !important;
// }
// .modal.fade {
//     transition: opacity 0s ease-in-out !important;
//     transition-delay: 0 !important;
// }
// .modal.show .modal-dialog {
//     transform: translate(0px, 0px) scale(1) !important;
//     opacity: 1 !important;
// }

// global tooltip style with this classname

.custom-tooltip-style {
  .tooltip-inner {
    background-color: $WhiteLight !important;
  }

  .tooltip-arrow {
    &:before {
      border-top-color: $ninth !important;
    }
  }
}

.common-form-tip {
  .tooltip-inner {
    background-color: #ffffff !important;
    box-shadow: 0px 10px 60px #00000029;
    max-width: 300px !important;
  }

  .tooltip-arrow {
    &:before {
      border-top-color: #ffffff !important;
    }
  }

  .bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow:before {
    border-right-color: #ffffff;
  }

  .bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow:before {
    border-top-color: #ffffff;
  }

  .bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow:before {
    border-bottom-color: #ffffff;
  }

  .bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow:before {
    border-left-color: #ffffff;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $secondary;
}

.custom-spiner {
  position: relative;
  min-height: 400px;

  .spinner-border {
    position: absolute;
    top: 48%;
    left: 48%;
  }
}

.template-img {
  img {
    border-radius: 5px !important;
  }
}

.modal-btn-group {
  .btn {
    &:first-child {
      margin-right: 16px;

      @media screen and (max-width: 480px) {
        margin-right: 0px;
        margin-bottom: 16px;
      }
    }
  }
}

.static-scrollbar-onhover {
  overflow: auto !important;

  &:hover {
    overflow: auto !important;

    &::-webkit-scrollbar-thumb {
      background: $third;
      border-radius: 10px;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: ;
  // }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: $third;
  // }
}

.modal-features {
  .modal-content {
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    max-height: 75% !important;
  }
}

.error-explore {
  object-fit: none !important;
}

.under-maintanance-wrapper {
  min-height: calc(100vh - 269px);

  h3 {
    font-size: 40px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 26px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {}

  .under-construction-image {
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 767px) {
      max-width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 144px);
  }
}

.d-fl-base {
  display: flex;
  align-items: baseline;
}

.font-18 {
  font-size: 18px;
}

.bottom {
  bottom: 10px !important;
}

.disabled_btn {
  color: inherit;
  opacity: 0.9;
  pointer-events: none;
}

.walletConnect {
  margin-bottom: 20px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.switchWalletConnect {
  margin-bottom: 20px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 25%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: white;
  font-weight: 600;
  margin-left: 5px;
}

.p-20 {
  padding: 20px;
}

.p-28 {
  padding: 28px;
}

.rounded-10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-size: 600;
}

.fw-700 {
  font-weight: 700;
}

.font-16 {
  font-size: 16px;
  line-height: 30px;
}

.font-20 {
  font-size: 20px;
  line-height: 24px;
}

.align-items-base-line {
  align-items: baseline;
}