/** @format */

@import "../variables";
.footer-wrapper {
  background: transparent linear-gradient(180deg, #09090900 0%, #090909 100%);
  .ft-title {
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .footer-page-link-list {
    a {
      color: $third;
      font-size: 14px;
      line-height: 36px;
      text-decoration: none;
      transition: all ease 0.3s;
      &:hover,
      &:focus,
      &:active {
        color: #ffffff;
        transition: all ease 0.3s;
      }
    }
  }
  .footer-input {
    position: relative;
    margin-bottom: 0px !important;
    input {
      padding-right: 50px;
    }
    .arrow-bl {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translate(0, -50%);
      font-size: 18px;
      color: #f71137;
      line-height: 18px;
      display: block;
    }
  }
  .social-media-block {
    margin-top: 50px;
  }
  .footer-first-block {
    margin-bottom: 100px;
  }
  .footer-second-block {
    border-top: 1px solid #303030;
    .privacy-block {
      display: flex;
      margin: 30px 0;
      li {
        padding-right: 15px;
        padding-left: 15px;
        border-right: 1px solid $third;
        line-height: 14px;
        font-size: 13px;
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
          border-right-color: transparent;
        }
        a {
          font-size: 13px;
          line-height: 14px;
          text-decoration: none;
          color: $third;
          transition: all ease 0.3s;
          &:hover,
          &:focus,
          &:active {
            color: #ffffff;
            transition: all ease 0.3s;
          }
        }
      }
    }
  }
}
.join-section{
  text-align: start !important;
}
@media screen and (max-width: 991px) {
  .footer-wrapper {
    .footer-first-block {
      margin-bottom: 30px;
    }
    .ft-title {
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-wrapper {
    .footer-second-block {
      padding: 15px 0;
      margin-bottom: 50px;
      .privacy-block {
        margin: 5px 0;
      }
    }
  }
  .join-section{
    text-align: center !important;
  }
}
@media screen and (max-width: 574px) {
  .footer-wrapper {
    .footer-first-block {
      margin-bottom: 30px;
      .social-link-list{
        justify-content: center;
      }
    }
    .footer-second-block {
      margin-top: 40px;
      padding: 15px 0;
      .privacy-block {
        margin: 5px 0;
      }
    }
  }
}
