@import '../variables';
.invoice-wrapper {
    .filter-wrapper {
        justify-content: space-between;

        .react-datepicker-wrapper {
            height: 50px;
            width: 170px;
            .start-date,
            .end-date {
                font-size: 14px;
                font-weight: normal;
                height: 50px;
                max-width: 170px;
            }
        }
        .purchase-plan-type {
            min-width: 190px;
            height: 50px;
            .purchase-plan__control {
                height: 50px;
            }
            .purchase-plan__single-value {
                font-weight: normal !important;
            }
        }
        .search {
            font-size: 14px;
            height: 50px;
            max-width: 150px;
        }
    }

    // @media screen and (max-width: 1200px) {
    //     .filter-wrapper {
    //         flex-wrap: wrap;
    //     }
    //     .search,
    //     .react-datepicker-wrapper,
    //     .purchase-plan-type {
    //         margin-top: 15px;
    //     }
    // }
    // @media screen and (max-width: 405px) {
    //     .search,
    //     .react-datepicker-wrapper,
    //     .purchase-plan-type,
    //     .start-date,
    //     .end-date {
    //         width: 100% !important;
    //         max-width: 100% !important;
    //     }
    // }
    .filter-select-wrapper .css-1pahdxg-control {
        background-color: #ffffff !important;
    }    
    .css-6j8wv5-Input ,.css-yt9ioa-option {
        color: $secondary !important;
      }
      .css-26l3qy-menu {
        background-color:#ffffff !important;
    }
      .css-1pahdxg-control {
        background-color:#ffffff !important;
      }
      .css-1pahdxg-control:hover {
        border-color: #ffffff !important;
        background-color: #ffffff !important;
      }
      .css-qc6sy-singleValue {
        border-color: #ffffff !important;
      }
      .css-qc6sy-singleValue,
      .css-1gtu0rj-indicatorContainer {
        color: $secondary !important;
      }
}

.search-wrapper-block{
    justify-content: flex-end;
    display: flex;
    .search-input{
        width: 400px;
        margin-right: 10px;
        
        input{
            border-radius: 10px!important; 
            height: 53px;
        }
    }
    .filter-button{
     border: 1px solid $ninth;
    background: #FFFFFF ;
    border-radius: 10px;
    font-size: 14px;
    line-height: 24px;
    color: $forth;
    height: 53px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover ,&:focus{
        color: $forth;
    }
    &.active{
        background-color: #DADCE0;
    }
}
}

.date-rng-pick-block{
    display: flex;
}
.main-wrap{
 margin-bottom: 15px;
background: #F8F8F8 ;
border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
.date-rng-pick-block{
    width: 65%;
    display: flex;
    align-items: center;
        .sm-date-picker-wrapper{
            margin-right: 10px;
            width: calc(50% - 5px);
            input{max-width: 100%!important;  height: 53px;}
            &:last-child{
                margin-right: 0;
            }
        }
    
    .lb-bl{
        width:80px;
    }
.date-picker-inner-bl{
    width: calc(100% - 92px);
    display: flex;
}

}
.d-select-bl{
        display: inline-flex;
        align-items: center;
        width: calc(35% - 20px);
        margin-left: 20px;
        .react-datepicker-wrapper{
            margin-right: 10px;
            width: calc(50% - 5px);
            .css-1s2u09g-control ,.css-1s2u09g-control{max-width: 100%!important;}
        }.lb-bl{
     margin-right: 20px;
     width: 92px;
     }   
     .css-b62m3t-container{
        width: calc(100% - 80px);
     }
    }


    @media screen and (max-width: 1200px) {
        margin-top: 20px;
        flex-wrap:wrap;
        .date-rng-pick-block {
            margin-bottom: 10px;
            width: 100%;
            justify-content: space-between;
             .date-picker-inner-bl {
                width: calc(100% - 112px);
              
            }
            .lb-bl {width: 120px;}
        }
        .d-select-bl{
            width: 100%;
            margin-left: 0px;
         
            .lb-bl {width: 100px;}
            .css-b62m3t-container
                {    width: calc(100% - 112px);

                } 
        }
    }
    @media screen and (max-width: 575px) {
        .date-rng-pick-block {
            margin-bottom: 10px;
            display: block;
            width: 100%;
            justify-content: space-between;
             .date-picker-inner-bl {
                width: 100%;
                display: block;
            }
            .lb-bl {width: 100%;
                }
                .sm-date-picker-wrapper{
                    width: 100%;    margin-bottom: 5px;
                    &:last-child{
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
                 .react-datepicker-wrapper {
                   margin-right: 0px;
                    width: 100%;
               
                 
                }
                margin-bottom: 15px;
        }
        .d-select-bl{
            width: 100%;
            margin-left: 0px;
            display: block;
            .lb-bl {width: 100px;}
            .css-b62m3t-container
                {    width:100%;

                } 
        }
    }
    .purchase-plan-type {
        min-width: 190px;
        height: 53px;
        .purchase-plan__control {
            height: 53px;
        }
        .purchase-plan__single-value {
            font-weight: normal !important;
        }
    }
    .search {
        font-size: 14px;
        height: 50px;
        max-width: 150px;
    }
    
}
