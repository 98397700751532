#loading {
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
}
#loading .circle {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0, 0);
    -webkit-animation: circle-animation 1.6s ease-in-out 0s infinite normal none;
    animation: circle-animation 1.6s ease-in-out 0s infinite normal none;
}
#loading.loaded {
    display: none;
}
#container {
    // width: 474px;
    // height: 474px;
    margin: auto;
    margin-left: 45px !important;
    @media screen and (max-width: 767px) {
        margin-left:0px !important;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}
#container canvas {
    top: 0;
    left: 0;
    z-index: 0;
    outline: none;
    width: 100%;
    height: 100%;
    transform: scale(1.6);
    margin-top: -61px;

    @media screen and (max-width: 501px) {
        height: 400px !important;
        width: 400px !important;
        margin-top: 0px;
    }
    @media screen and (max-width: 400px) {
        height: 300px !important;
        width: 300px !important;
        margin-top: 0px;
    }
}
/** css animation */
@-webkit-keyframes circle-animation {
    0% {
        opacity: 0;
        transform: scale(0, 0);
    }
    50% {
        opacity: 1;
        transform: scale(1, 1);
    }
}
@keyframes circle-animation {
    0% {
        opacity: 0;
        transform: scale(0, 0);
    }
    50% {
        opacity: 1;
        transform: scale(1, 1);
    }
}
