@import "../variables";
.requestdemo-pop {
  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 733px;
    }
  }
  .content {
    // padding: 0 0 40px 0;
    margin-top: 40px;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      padding: 0 0 20px 0;
      margin-top: 20px;
    }
  }
  .error-msg {
    color: hsl(350deg 93% 52%);
    text-align: left;
    font-size: 13px;
    font-size: 500;
    margin-top: 0;
  }

  .demo-popup__placeholder {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }

  .demo-popup__single-value {
    text-align: left;
    font-size: 14px;
    font-weight: 700 !important;
    color: #090909 !important;
  }

  .dropwdown {
    &::placeholder {
      text-align: left !important;
    }
  }
  .form-control {
    font-size: 14px !important;
  }
  .meta-container {
    // justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    
    .input-container {
      width: calc(50% - 8px);
      .form-control {
        width: 100%;
        max-width: 100%;
      }
      &:first-child {
        margin-right: 8px;
      }
      &:nth-child(2) {
        margin-left: 8px;
      }
    }
    @media screen and (max-width: 767px) {
      .input-container {
        width: 100%;
        &:first-child {
          margin-right: 0px;
        }
        &:nth-child(2) {
          margin-left: 0px;
        }
        .form-control {
          width: 100% !important;
        }
      }
    }
  }
  .request-demo-email-field {
    width: 100% !important;
  }

  .red-border-requestDemo {
    border-color: #f51138 !important;
  }

  .metaData-overFollow {
    max-height: 100% !important;
    overflow-y: unset;
    height: 100%;
  }
  .modal-content {
    max-height: 100% !important;
  }
  .metaData-overFollow {
    max-height: 100% !important;
  }
  .max-category-menu .css-4ljt47-MenuList ,.max-category-menu .css-1w9j89e-menu{
    text-align: left;
  }
  .css-4ljt47-MenuList ,.css-1w9j89e-menu {
    background-color: #090909;
  }
}
