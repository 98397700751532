@import "../variables";
.credits {
  min-height: calc(100vh - 150px);
  @media screen and (max-width: 767px) {
    padding: 25px 0;
  }
  &-header {
    h2 {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  &-left {
    &-available {
      padding: 39px 30px;
      font-size: 22px;
      @media screen and (max-width: 767px) {
        padding: 25px;
        font-size: 20px;
      }
      span {
        font-weight: bold;
      }
    }
    &-add {
      padding: 30px;
      @media screen and (max-width: 767px) {
        padding: 25px;
        margin-bottom: 15px;
      }
      .chip-wrapper {
        padding: 20px 0;
        gap: 5px;
        .semiround-chip {
          padding: 3px 20px;
          border: 1px solid $ninth;
          border-radius: 18px;
          cursor: pointer;
          background-color: $white;
          &:hover {
            color: $primary;
          }
          &.selected-chip {
            color: $primary;
            border-color: $primary;
          }
        }
      }
      .form-wrapper {
        gap: 10px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        input {
          font-size: 14px;
          flex: 0 0 calc(100% - 180px);
          @media screen and (max-width: 767px) {
            flex: 0 0 auto;
            height: 53px;
          }
        }
        button {
          flex: 0 0 170px;
          @media screen and (max-width: 767px) {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
  &-right {
    padding: 30px 25px 10px 30px;
    @media screen and (max-width: 767px) {
      padding: 25px 10px 10px 15px;
    }
    &-table {
      max-height: 540px;
      min-height: 240px;
      overflow-y: auto;
      padding-right: 5px;
      padding-bottom: 30px;
      @media screen and (max-width: 1399px) {
        height: 315px;
        max-height: 315px;
      }
      @media screen and (max-width: 767px) {
        height: auto;
        max-height: 390px;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($primary, 0.75);
        border-radius: 25px;
      }
      table {
        & > :not(:first-child) {
          border-top-width: 0;
        }
        th,
        td {
          padding: 18px 20px;
          @media screen and (max-width: 767px) {
            padding: 12px 18px;
          }
        }
        .credit-up {
          color: #32bea6;
        }
        .credit-down {
          color: #e04f5f;
        }
      }
    }
    &-filter {
      color: $tenth !important;
      padding: 5px;
      &:hover {
        color: $tenth !important;
      }
    }
    .form-control {
      height: 45px;
      font-size: 14px;
    }
  }
}
