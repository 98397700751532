/** @format */
@import "../variables";

.social-link-list {
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;

  li {
    width: 58px;
    height: 58px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    a, .a {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      background-color: $fifth;
      color: $third;
      justify-content: center;
      transition: all ease 0.5s;
      border-radius: 10px;
      &:hover,
      &:focus,
      &:active {
        background-color: #ffffff;
        color: $primary;
        transition: all ease 0.5s;
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  .social-link-list {
    li {
      width: 48px;
      height: 48px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .social-link-list {
    li {
      width: 45px;
      height: 45px;
      margin-right: 7px;
    }
  }
}

@media screen and (max-width: 574px) {
  .social-link-list {
    li {
      width: 45px;
      height: 45px;
    }
  }
}
@media screen and (max-width: 374px) {
  .social-link-list {
    li {
      width: 40px;
      height: 40px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
