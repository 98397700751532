@import "../variables";

.create-collection-modal {
    @media (min-width: 991px) {
        max-width: 767px !important;
    }

    @media (min-width: 1199px) {
        max-width: 1000px !important;
        height: 500px !important;
    }

    @media (max-width: 990px) {
        max-width: 500px !important;
    }

    @media (max-width: 575px) {
        max-width: 100% !important;

    }
}

.modal-content {
    max-height: 645px;
    overflow-y: auto;
    border: 0;
    /* width */
    overflow: auto !important;

    &:hover {
        overflow: auto !important;

        &::-webkit-scrollbar-thumb {
            background: $primary;
            border-radius: 10px;
        }
    }

    /* width */
    &::-webkit-scrollbar {
        width: 0px;
    }

    // /* Track */
    // &::-webkit-scrollbar-track {
    //   background: ;
    // }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }

}

.info-tooltip {
    color: #868686;
    opacity: 0.5;
    transition: all ease 0.1s;
    display: inline-flex;

    &:hover {
        color: $primary;
        opacity: 1;
    }

    margin-left: 5px;
}

.common-image-bl {
    //  border: 1px dashed $ninth;
    border-radius: 5px;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    background-image: repeating-linear-gradient(0deg, #dadce0, #dadce0 6px, transparent 6px, transparent 15px, #dadce0 15px), repeating-linear-gradient(90deg, #dadce0, #dadce0 6px, transparent 6px, transparent 15px, #dadce0 15px), repeating-linear-gradient(180deg, #dadce0, #dadce0 6px, transparent 6px, transparent 15px, #dadce0 15px), repeating-linear-gradient(270deg, #dadce0, #dadce0 6px, transparent 6px, transparent 15px, #dadce0 15px);
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;

    .before-image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .after-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 15px;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 100%;

            &:not(.logo) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    svg {
        font-size: 50px;
        color: $ninth;
    }

    .text-note {
        position: absolute;
        bottom: 5px;

        p {
            font-size: 12px;

        }
    }
}

.create-min-form {
    .social-bt-wrap {
        .form-input-mb {
            margin-bottom: 10px !important;
        }

        .social-input {
            margin-bottom: 0px !important;
        }

        .two-input {
            .form-input-mb {
                width: calc(50% - 5px);

                &:first-child {
                    margin-right: 5px;

                    @media (max-width: 991px) {
                        margin-right: 0px;

                    }
                }

                &:nth-child(2) {
                    margin-left: 5px;

                    @media (max-width: 991px) {
                        margin-left: 0px;

                    }
                }

                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }

}

.form-control.is-invalid {
    background-image: none !important;
}

.border-error {
    border: 1px solid $primary;
    border-radius: 5px;
}

.border-error-dotted {
    background-image: repeating-linear-gradient(0deg, $primary, $primary 6px, transparent 6px, transparent 15px, $primary 15px),
        repeating-linear-gradient(90deg, $primary, $primary 6px, transparent 6px, transparent 15px, $primary 15px),
        repeating-linear-gradient(180deg, $primary, $primary 6px, transparent 6px, transparent 15px, $primary 15px),
        repeating-linear-gradient(270deg, $primary, $primary 6px, transparent 6px, transparent 15px, $primary 15px);
    background-size: 1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
    background-position: 0 0,
        0 0,
        100% 0,
        0 100%;
    background-repeat: no-repeat;
}

html body div.position-relative.mb-40px {
    margin-bottom: 40px !important;
}