@import "../variables";
.create-nft-detailpopup {
  position: fixed;
  width: calc(100% - 40px);
  height: 100vh;
  margin: 20px;
  top: 0;
  left: 0;
  z-index: 1001;
  background: $secondary;
  border-radius: 20px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  opacity: 1;
  padding: 30px;

  &::-webkit-scrollbar {
    width: 0px;
    background: lightgray;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 255, 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
  }
  @media (max-height: 767px) {
    height: 1024px;
    overflow-y: auto;
  }
  @media screen and (max-width: 991px) {
    width: calc(100% - 30px);
    height: 100vh;
    margin: 15px;
    padding: 15px;
  }
  .popup-header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
      display: block;
      margin-bottom: 20px;
    }
    .blk_1 {
      .detail-card-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        color: #ffffff;
        margin-top: 30px;
        @media screen and (max-width: 1199px) {
          margin-top: 10px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
        }
      }
    }
    .blk_2 {
      .button-blk {
        background-color: #ffffff;
        width: 300px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 10px;
        img {
          max-height: 100%;
          max-width: 100%;
          border-radius: 4px;
        }
        @media screen and (max-width: 1199px) {
          width: 230px;
          height: 85px;
          img {
            height: 75px;
          }
        }
        @media screen and (max-width: 991px) {
          width: auto;
          max-width: 200px;
          height: auto;
          padding: 20px 30px;
          margin-top: 15px;
        }
      }
    }
  }
  .divider {
    background-color: $seventh;
    height: 1px;
    width: 100%;
    display: block;
    content: " ";
    // margin: 40px 0;
  }
  .information-wrapper {
    display: flex;
    max-height: 100%;
    max-height: calc(100% - 120px);
    padding: 40px 0;
    height: 100%;
    @media screen and (max-width: 991px) {
      display: block;
      overflow-y: auto;
      padding: 20px 0;
    }
    .info-inner-wrapper-1 {
      width: 470px;
      display: inline-flex;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid $seventh;
      @media screen and (max-width: 1499px) {
        width: 400px;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        border-right: 0px solid $seventh;
        border-bottom: 1px solid $seventh;
        height: auto;
      }
      .content-text {
        font-weight: 700;
        margin-bottom: 15px;
        padding-right: 10px;
      }
      .label-title {
        margin-bottom: 15px;
        font-weight: 400;
      }
      .info {
        height: calc(100% - 30px);
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
    }
    .info-inner-wrapper-2 {
      width: calc(100% - 470px);
      padding-left: 40px;
      height: 100%;
      @media screen and (max-width: 1499px) {
        width: calc(100% - 400px);
      }
      @media screen and (max-width: 991px) {
        padding-left: 00px;
        padding-top: 20px;
        border-bottom: 0px solid $seventh;
        width: 100%;
      }
    }
  }
  .nav-tabs {
    background-color: #191919;
    border-bottom: 0px;
    padding: 5px 5px;
    border-radius: 10px;
    .nav-link {
      font-size: 14px;
      color: #090909;
      margin-right: 5px;
      border-radius: 5px;
      color: $third;
      padding: 16px 30px;
      opacity: 1;
      &:hover {
        border-color: transparent;
      }
      @media screen and (max-width: 1199px) {
        padding: 10px 20px;
      }
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      color: #090909;
    }
  }
  .uploadfile-list {
    list-style-type: none;
    margin-top: 20px;
    max-width: fit-content;
    white-space: nowrap;
    overflow-x: auto;
    li {
      height: 300px;
      margin-right: 20px;
      border-radius: 10px;
      overflow: hidden;
      display: inline-block;
      width: fit-content;
      @media screen and (max-width: 1499px) {
        height: 240px;
      }
      img {
        width: auto;
        height: 100%;
      }
      .wrap_bl_iv {
        width: auto;
        height: 100%;
      }
    }
  }

  .wrapper-title {
    @media screen and (max-width: 991px) {
      margin-bottom: 15px;
    }
  }
}

// .slick-dots.slick-thumb li {
//   height: 70px;
//   width: 70px;
//   margin-right: 10px;
//   opacity: 0.1;
//   .slick-active {
//     opacity: 1;
//   }
//   img {
//     object-fit: cover;
//     width: 100%;
//     height: 100%;
//   }
// }

@media (min-width: 991px) {
  .image-carousel-bmodal {
    max-width: 798px !important;
  }
}
@media (max-width: 990px) {
  .image-carousel-bmodal {
    max-width: 500px !important;
  }
}
@media (min-width: 991px) {
  .create-nft-third-btn-modal {
    max-width: 700px !important;
  }
}
@media (max-width: 990px) {
  .create-nft-third-btn-modal {
    max-width: 500px !important;
  }
}
@media (max-width: 575px) {
  .create-nft-third-btn-modal {
    max-width: 100% !important;
  }
}
.slick-block-1 {
  width: 628px;
  height: 400px;
  border-radius: 5px;

  margin: 35px auto 0px;
  img {
    border-radius: 5px;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  video {
    width: 100%;
    object-fit: cover;
    height: 400px;
    border-radius: 5px;
  }
}
.slick-block-2 {
  width: 500px;
  overflow: hidden;
  margin: 10px auto;

  .slick-slide {
    opacity: 0.1;
    &.slick-active.slick-current {
      opacity: 1;
    }
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 5px;
    }
    video {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
.slick-prev:before,
.slick-next:before {
  // color: red !important;
  display: none;
}
.slick-prev,
.slick-next {
  h3 {
    font-size: 30px;
    color: $forth;
    transition: all ease 0.15s;
  }
}
.slick-prev:hover,
.slick-next:hover {
  h3 {
    color: $primary;
    transition: all ease 0.15s;
  }
}
.slick-prev {
  left: -50px !important;
}
.slick-next {
  right: -50px !important;
}

@media (max-width: 990px) {
  .slick-block-1 {
    width: 100%;
    height: 300px;
    margin: 25px auto 0px;
  }
  .slick-block-2 {
    width: 100%;
    .slick-slide {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .slick-prev {
    left: -25px !important;
  }
  .slick-next {
    right: -25px !important;
  }
}

@media (max-width: 480px) {
  .slick-block-1 {
    width: 100%;
    height: 215px;
  }
  .slick-block-2 {
    width: 80%;
    .slick-slide {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
@media (max-width: 480px) {
  .w-btn-100 {
    width: 100% !important;
  }
}

.custome-Attribute {
  max-height: 100px;
  overflow: hidden;
  &:hover {
    overflow: auto;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $secondary;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $seventh;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $third;
  }
}


