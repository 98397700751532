@import "../variables";
@import "../styles.scss";

.global-confirmation-back {
  opacity: 0.2 !important;
}

.global-confirmation-modal {
  .modal-content {
    .modal-body {
      overflow: hidden;
      padding: 30px;
      position: relative;
      background: $white;
      min-height: 150px;
      border-radius: 5px;
      .api-loader {
        position: absolute;
        border-radius: inherit;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 20000;
        background-color: rgba($color: $secondary, $alpha: 0.3);
        width: 100%;
        height: 100%;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .content-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 150px;
        .header-content {
          padding: 20px;
          text-align: center;
          flex: 1;
          h4 {
            font-size: 24px;
          }
          p {
            color: $secondary;
          }
        }
        .btn-Section {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}


.c-img {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}