@import "../variables";
/*radio button design start*/
.add-nft-checkbox-wrapper{
label {
    width: 100%;
  }
  .card-input-element  , .card-radio-element {
    display: none;
  }
  .card-input {
    margin: 0px;
    padding: 0 0px;
    width: 100%;
    border: 0px solid $ninth;
    border-radius: 5px;
    height: 70px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .card-input:hover {
    cursor: pointer;
  }
  .custom-check-btn {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 5px;
    border: 1px solid $ninth;
    min-width: 20px;
    min-height: 20px;
    
    &:after {
      position: absolute;
      width: 13px;
      height: 13px;
      top: 50%;
      left: 50%;
      display: none;
      content: " ";
      transform: translate(-50%, -50%);
      background-color: $primary;
      border-radius: 3px;
    }
  }
  .custom-radio-btn {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    border: 1px solid $ninth;
    min-width: 20px;
    min-height: 20px;
    
    &:after {
      position: absolute;
      width: 13px;
      height: 13px;
      top: 50%;
      left: 50%;
      display: none;
      content: " ";
      transform: translate(-50%, -50%);
      background-color: $primary;
      border-radius: 50%;
    }
  }
  .card-input-element:checked + .card-input {
    border: 0px solid $primary;
    width: 100%;
    .custom-check-btn ,.custom-radio-btn {
      border: 1px solid $primary;
      &:after {
        display: block;
      }
    }
  }
  .card-input-element:disabled + .card-input {
    opacity: 0.5;
  }
 
 
.content-bl{
    display: flex;
    margin-left: 10px;
    min-width: calc(100% - 35px);
    width: calc(100% - 35px);
  .wrap_1{
    width: 70px;
    height: 70px;
    border: 1px solid $ninth;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
    display: inline-flex;
    &.rounded-wrap{
      border-radius: 50%;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  .wrap_2{
    padding: 10px 0;
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 85px);
    justify-content: left;
    .nft-number-block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 480px) {
            display: block;
            text-align: left;
        }
        .bl_1{
            .number{
                font-size: 18px;   
                
            }
        }
        .bl_2{
            .counter{
                display: flex;
                li{
                    margin-right: 16px;
                    display: inline-flex;
                    align-items: center;
                    &:last-child{
                        margin-right: 0px;
                    }    
                    .count{
                        margin-left: 5px;
                    }
                }
                
            }
        }
    }
}
}
}
  /*radio button design end*/