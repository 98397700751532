@import "../variables";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.main_tl {
  div {
    color: $primary;
    font-weight: 700;
  }
  .swiper1 {
    animation: profile 5s infinite;
  }
}

.banner-wrapper-new {
  height: calc(100vh - 82px);
  .banner-section_1 {
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    width: 100%;
    .inner_wrapper {
      display: block;
      width: 100%;
    }
  }
  @media screen and (min-width: 1199px) and (max-height: 767px) {
    height: 900px;
  }
  @media screen and (max-width: 1199px) {
    height: auto;
  }
}
.logo-portion-list {
  .support-list {
    display: flex;
    align-items: center;
    padding: 30px 60px;
    background-color: $secondary;
    border-radius: 20px;
    justify-content: space-between;
    img {
      // height: 40px;
      width: auto;
      max-width: 130px;
    }
    li {
      &:nth-child(2) {
        img {
          // height: 46px;
        }
      }
    }
    @media screen and (max-width: 1199px) {
      padding: 20px 30px;
    }

    @media screen and (max-width: 767px) {
      padding: 15px 20px;
    }
  }
}

.videomodle {
  .modal-content {
    overflow: unset !important;
    background-color: transparent;
    border-radius: 10px;
    &:hover {
      overflow: unset !important;
      border-radius: 10px;
      background-color: transparent;
    }
    .video-close-btn {
      right: -17px !important;
      top: -17px !important;
      background-color: hsl(0deg 0% 100%);
      border-radius: 50%;
      svg {
        width: 35px;
        height: 35px;
        path {
          fill: $primary;
        }
      }
    }
    iframe {
      border-radius: 10px;
      width: 100%;
      height: 400px;
    }
    @media screen and (max-width: 1399px) {
      width: 100%;
      iframe {
        width: 100%;
      }
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      iframe {
        width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
}

.demo-popup__control--menu-is-open {
  text-align: left !important;
}

.logo-portion-list {
  .support-list {
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      li {
        &:first-child {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.home-wrapper {
  .banner-wrapper {
    margin-bottom: 65px;
    .banner-content-wrapper {
      padding: 50px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .welcome-content {
        margin: 40px 0 50px 0;
        @media screen and (max-width: 991px) {
          margin: 20px 0 25px 0;
        }
      }
      .block-chain-supported {
        @media screen and (max-width: 991px) {
          margin: 25px 0 0px 0;
        }
      }
      .support-list {
        display: flex;
        align-items: center;
        margin-top: 20px;
        li {
          display: inline-flex;
          margin-right: 40px;
          height: 32px;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    .carousel-inner {
      border-radius: 10px;
      .carousel-item {
        img {
          width: 100%;
          height: 530px;
          object-fit: cover;
          @media screen and (max-width: 1199px) {
            height: 430px;
          }
        }
        a {
          text-decoration: none;
        }
      }
      .carousel-caption {
        width: 100%;
        position: static;
        background-color: #ffffff;
        padding: 38px 30px;
        text-align: left;
        h3 {
          color: $secondary !important;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 0;
          font-weight: 700;
          text-decoration: none;
        }
        p {
          color: $primary;
          margin-bottom: 0;
          font-weight: 700;
        }
      }
    }
  }

  .banner-image-wrapper {
    position: relative;
    a {
      text-decoration: none;
    }
    border-radius: 10px;
    overflow: hidden;
    .banner-item {
      width: 100%;
      height: 650px;
      object-fit: cover;
      display: flex;
      flex-direction: column;
      background-color: red;
      @media screen and (max-width: 1199px) {
        height: 530px;
      }
      @media screen and (max-width: 991px) {
        height: 650px;
      }
      @media screen and (max-width: 767px) {
        height: 530px;
      }
      a {
        text-decoration: none;
      }
      .banner-item-first {
        flex-grow: 1;
        display: inline-flex;
      }
      .banner-caption {
        width: 100%;
        position: static;
        background-color: #ffffff;
        padding: 38px 30px;
        text-align: left;
        h3 {
          color: $secondary !important;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 0;
          font-weight: 700;
          text-decoration: none;
        }
        p {
          color: $primary;
          margin-bottom: 0;
          font-weight: 700;
        }
      }
    }
  }

  .create-and-sell-nft-block {
    background-color: #ffffff;
    height: 275px;
    display: block;
    width: 100%;
    border-radius: 10px;
    padding: 20px 30px;
    .title-number {
      font-size: 60px;
      line-height: 76px;
      color: $primary;
      @media screen and (max-width: 767px) {
        font-size: 43px;
        line-height: 43px;
      }
    }
    .title-wrapper {
      display: flex;
      align-items: center;
      .block-1 {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 574px) {
      height: auto;
    }
  }
  /*create and sell nft section end*/
  /*Top User section start*/
  .top-user-main-control-wrapper {
    height: 311px;
    @media screen and (max-width: 1199px) {
      height: auto;
    }
  }
  .top-user {
    .top-user-wrapper {
      // background-color: #ffffff;
      border-radius: 10px;
      transition: all 0.5s;
      .img-wrapper {
        height: 110px;
        width: 100%;
        overflow: hidden;
        border-radius: 10px 10px 0px 0;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.5);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .user-block {
        margin-top: -50px;
        display: flex;
        text-align: center;
        justify-content: center;

        .user-img-wrapper {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 3px solid #ffffff;
          position: relative;
          transition: all ease 0.5s;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: all ease 0.5s;
          }
        }
        .active-bl {
          position: absolute;
          right: 9px;
          bottom: 8px;
          width: 12px;
          height: 12px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: block;
          content: " ";
          background-color: $eight;
        }
      }
      .username-block {
        padding-left: 30px;
        padding-right: 30px;
        h3 {
          max-width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .checkmark-block {
          font-size: 12px;
          line-height: 12px;
          margin-top: 2px;
          margin-left: 3px;
          color: $primary;
        }
      }
      .nft-ct {
        margin-top: 0px;
        padding-bottom: 10px;
      }
      &:hover {
        .user-img-wrapper {
          width: 110px;
          height: 110px;
          transition: all ease 0.5s;
          img {
            transition: all ease 0.5s;
          }
        }
      }

      @media screen and (max-width: 1199px) {
        margin-bottom: 30px;
      }
    }
  }

  .notable-nft {
    .exploring-link {
      text-decoration: none;
    }
    .notable-nft-wrapper {
      background-color: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      .img-wrapper {
        height: 343px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .nft-content-block {
        padding: 30px;
        .nft-content {
          height: 45px;
          max-height: 45px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .exploring-link {
          font-size: 18px;
          line-height: 32px;
          text-decoration: none;
          color: $primary;

          display: block;
          width: fit-content;
          margin: 0 auto;
          margin-top: 20px;
        }
      }

      @media screen and (max-width: 1199px) {
        .img-wrapper {
          height: 280px;
        }
        .nft-content-block {
          padding: 15px;
          .nft-content {
            height: 35px;
            max-height: 35px;
          }
          .exploring-link {
            font-size: 14px;
            line-height: 26px;
            margin-top: 10px;
            text-decoration: none;
          }
        }
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
  }
  .btn-block {
    margin-top: 50px;
    @media screen and (max-width: 991px) {
      margin-top: 30px;
    }
  }
}

.latest-artical {
  .artical-wrapper {
    // background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    .img-wrapper {
      height: 343px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .art-content-block {
      padding: 30px;
      background-color: #ffffff;
      .art-title-bl {
        height: 78px;
        @media screen and (max-width: 767px) {
          height: 50px;
        }
      }
      .art-content {
        margin-top: 24px;
      }
    }
    .capsual-block {
      background: $primary;
      border: 2px solid #ffffff;
      border-radius: 20px;
      font-size: 11px;
      line-height: 11px;
      position: absolute;
      bottom: -9px;
      right: 18px;
      padding: 3px 15px;
      color: #ffffff;
    }
    .hide-overflow-content {
      width: auto;
      max-width: calc(100% - 36px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
      .img-wrapper {
        height: 280px;
      }
    }
  }
}

.digital-assets {
  .welcome-title {
    margin: 40px 0;
  }
  .exploring-block {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      display: block;
      .img-wrapper-first {
        transform: rotate(90deg);
        margin: 63px 40px;
      }
    }
  }
  .img-wrapper-first {
    margin: 0 40px;
  }
  .img-wrapper-second {
    position: relative;
    cursor: pointer;
    img {
      animation: rotation 20s infinite linear;
    }
  }

  .videomodle {
    width: 889px;
    height: 500px;
  }

  .videomodle {
    width: 889px;
    height: 500px;

    @media (max-width: 767px) {
      margin-left: -2%;
    }

    .modal-header {
      position: absolute;
      right: -35px;
      top: -35px;
      z-index: 99;
      font-size: 14px !important;
      border-bottom: none;

      h5 {
        div {
          cursor: pointer;
          display: flex;
          border: 1px solid #073d83;
          border-radius: 50%;
          overflow: hidden;
          width: 40px;
          height: 40px;
          background-color: #073d83;
          color: #fff !important;
        }
      }
    }

    .modal-content {
      width: auto;
    }
  }

  .play-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 63px;
    height: 63px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    border-radius: 50%;
  }
  .testimonial-user-image-list {
    .user-image-testimonial {
      margin-bottom: 30px;
      height: 195px;
      width: 100%;
      border-radius: 10px;
      &:hover,
      &:focus,
      &:active,
      &.active {
        img {
          width: 100%;
          height: 100%;
          -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
          filter: grayscale(0%);
          transition: all ease 0.2s;
        }
      }
      img {
        width: 100%;
        height: 100%;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        cursor: pointer;
        transition: all ease 0.2s;
      }
      &:nth-child(2) {
        margin-bottom: 0px;
      }

      @media screen and (max-width: 1399px) {
        height: 170px;
      }
      @media screen and (max-width: 991px) {
        height: 150px;
        &:nth-child(2) {
          margin-bottom: 0px;
          margin-right: 0px;
          padding-right: 0;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }
  }
  .carousel {
    height: 100%;
  }
  .carousel-inner,
  .carousel-item {
    height: 100%;
    h3 {
      font-size: 26px;
      line-height: 44px;
      font-weight: 700;
      @media screen and (max-width: 1199px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    p {
      font-size: 20px;
      line-height: 25px;
      color: $primary;
      font-weight: 700;
      @media screen and (max-width: 1199px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .carousel-caption {
    left: 0;
    top: 0;
    position: relative;
    padding: 0px;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
.testimonial-block-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: $fifth;
  border-radius: 10px;
}
