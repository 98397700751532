/** @format */

@import "../variables";

.draft .content-block,
.favourite .content-block {
  background-color: $WhiteLight !important;
}

.create-flow-wrapper {
  min-height: calc(100vh - 255px);
  display: flex;
  // align-items: center;
  margin: 50px 0;
  .max-content-title-and-wrapper {
    height: calc(100vh - 265px);
    overflow-y: auto;
    min-height: 525px;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0em;
      display: none;
    }
    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 210px);
  }
  .subtitle {
    font-size: 36px;
    line-height: 36px;
    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .ct-btn-mr {
    margin-right: 40px;
  }
  .user-wrapper {
    .img-wrapper {
      width: 100%;
      height: 530px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      // background-color: $ninth;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .image-loader-panding {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        flex-direction: column;
      }
      .loading-border-radius {
        border-radius: 10px;
        overflow: hidden;
      }
      .content-block {
        width: calc(100% - 40px);
        padding: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        position: absolute;
        bottom: 20px;
        background: #ffffff;
        border-radius: 5px;
        text-align: center;
      }
      @media screen and (min-width: 1199px) and (max-width: 1920px) and(max-height: 600px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 991px) {
        margin: 0 auto;
        width: 350px;
        height: 355px;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
        width: 350px;
        height: 355px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 574px) {
        height: 355px;
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}

.form-control,
.form-select {
  border-radius: 5px !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $forth;
    font-weight: 400;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
    color: $forth;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: 400;
    color: $forth;
  }
}

.set-price-input {
  .set-price-eth-wrapper {
    position: relative;
    margin-bottom: 0px !important;
    overflow: hidden;
    border-radius: 5px;
  }
  .form-control {
    padding-left: 120px;
    color: $secondary;
    font-weight: 700;
    padding-right: 100px;
    border-color: $primary;
  }
  .eh-start {
    position: absolute;
    height: 100%;
    top: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    padding-top: 2px;
    .icon {
      margin-right: 5px;
      img {
        padding-bottom: 5px;
      }
    }
  }
  .price {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
  }
  .form-control {
  }
}
.auction-list {
  margin-top: 20px;
  li {
    p {
      margin-bottom: 15px;
    }
    &:last-child {
      p {
        margin-bottom: 0px;
      }
    }
  }
}

.create-min-form {
  .form-input-mb {
    margin-bottom: 20px !important;
  }
  .form-control {
    color: $secondary;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px !important;
    padding-right: 75px;
  }
  .character-counter {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    p {
      font-size: 12px;
      color: $forth;
      opacity: 0.5;
    }
    &.text-area {
      position: absolute;
      right: 20px;
      top: auto;
      bottom: 20px !important;
      transform: translate(0, 0%);
    }
  }
  textarea {
    &.form-control {
      padding-top: 20px;
      padding-bottom: 20px;
      height: 125px !important;
    }
  }
}

.create-min-form {
  .react-tag-input {
    padding: 20px !important;
  }
  .react-tag-input__input {
    color: $secondary;
    font-size: 14px;
    line-height: 24px !important;
    padding-right: 75px;
    font-size: 14px;
    font-weight: 700;
    line-height: 36px;
    padding: 0px;
    color: #090909;
    box-shadow: none !important;
    outline: none !important;
    &::placeholder {
      font-weight: 400;
    }
  }
}

.addmetadata {
  transition: all 0.3s;
}
.addmetadata:hover {
  color: $primary;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  height: 63px;
  border-color: $ninth !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
  outline: none !important;
  border-color: $forth !important;
}
.css-6j8wv5-Input input,
.css-qc6sy-singleValue {
  color: $secondary !important;
  font-weight: 700 !important;
}
.css-319lph-ValueContainer {
  padding: 0 20px !important;
}
.css-1pahdxg-control:hover {
  border-color: $forth !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-tlfecz-indicatorContainer {
  &:nth-child(2) {
    // display: none !important;
  }
}
.delete-link {
  margin-bottom: 16px;
}

/*radio button design start*/
label {
  width: 100%;
}
.card-input-element {
  display: none;
}
.card-input {
  margin: 0px;
  padding: 0 20px;
  width: 100%;
  border: 1px solid $ninth;
  border-radius: 5px;
  height: 83px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .price {
    margin-left: auto;
    margin-right: 20px;
    font-size: 16px;
  }
}

.card-input:hover {
  cursor: pointer;
}
.custom-radio-btn {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 1px solid $ninth;
  &.radius-5 {
    border-radius: 5px;
  }
  &:after {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    display: none;
    content: " ";
    transform: translate(-50%, -50%);
    background-color: $primary;
    border-radius: 50%;
  }
}
.card-input-element:checked + .card-input {
  border: 1px solid $primary;
  width: 100%;
  .custom-radio-btn {
    border: 1px solid $primary;
    &:after {
      display: block;
    }
  }
}
.card-input-element:disabled + .card-input {
  opacity: 0.5;
  cursor: not-allowed;
}
/*radio button design end*/
.carousel-inner,
.carousel-item {
  height: 100%;
}
.card-wrapper-block {
  background-color: #f8f8f8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 54px;
  height: 100%;
  @media screen and (max-width: 991px) {
    padding: 15px;
  }
}
.wareenty-card {
  border: 1px solid #dadce0;
  border-radius: 5px;
  width: 100%;
  .inner-wrapper-1 {
    padding: 20px 0px;
    border-bottom: 1px solid #dadce0;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  .inner-wrapper-2 {
    padding: 20px 14px;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  .lg-image {
    width: 118px;
    text-align: end;
    img {
      height: 40px;
      border-radius: 5px;
    }
  }
  .title-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-content-block {
    margin-top: 25px;
    padding-right: 20px;
    padding-left: 20px;
    max-height: 150px;
    overflow-y: auto;
    min-height: 150px;
    // overflow: hidden;
    // &:hover {
    //   overflow-y: scroll;
    // }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
    }
    &:hover {
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    li {
      display: flex;
      margin-bottom: 10px;
      .block_1 {
        width: 50%;
        display: inline-flex;
      }
      .block_2 {
        width: 50%;
        display: inline-flex;
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
  @media (hover: none) {
    .card-content-block {
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .link-wrapper {
    display: flex;
    li {
      display: inline-flex;
      width: 25%;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dadce0;
      a {
        font-size: 16px;
        cursor: pointer;
        display: inline-flex;
        font-weight: 700;
        svg {
          transition: all ease 0.5s;
        }
        &:hover {
          color: $primary;
          svg {
            color: $primary;
            transition: all ease 0.5s;
          }
        }
        .icon-block {
          margin-right: 6px;
        }
      }
      &:last-child {
        border-right: 1px solid transparent;
      }
    }
  }
}

.custom-carouselIndicators {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 5px;
  outline: none;
  border: 0px solid;
  transition: all 1s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ninth;
}
.custom-carouselIndicators.active {
  width: 15px;
  background-color: $primary;
  border-radius: 15px;
}
// .custom-sub-custom-carouselIndicators {
//   width: 10px;
//   height: 10px;
//   background: #f51138;
//   position: absolute;
//   border-radius: 50%;
//   animation: cus 1s;
// }

@keyframes cus {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custome-CarouselCaption {
  background-color: #ffffff;
  border-radius: 5px;
  // padding: 10px 0;
  right: 10% !important;
  left: 10% !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  h3 {
    color: $secondary;
    font-weight: 700;
    margin: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    color: $primary;
    font-weight: 700;
    margin-bottom: 0px;
  }
}
.max-category-menu {
  .css-4ljt47-MenuList {
    max-height: 118px !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      background-color: #808080;
    }
  }
  .category-type__menu-list {
    max-height: 118px !important;
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      // background-color: rgba(255, 255, 255, 0.5);
      background-color: #808080;
      border-radius: 5px;
    }
  }
}

.metadata-list {
  justify-content: space-between;
  .meta-date-element {
    width: calc(24%);
    .form-input-mb {
      padding: 5px;
      width: 100%;
    }
  }
}
