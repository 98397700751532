.search-bar-main {
    z-index: 10;
    position: fixed;
    top: 0;
}

.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 415px;
    width: 1090px;
    height: 63px;
    /* UI Properties */
    background: var(---ffffff-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(---303030-dt-border);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #303030;
    border-radius: 10px;
    z-index: 10;
    box-shadow: 3px 4px 9px 0px #2c2c2b36;
    transition: all 0.5s;
    @media screen and (max-width: 1280px) {
        left: 95px;
    }
    @media screen and (max-width: 1025px) {
        left: 25px;
        width: 95vw;
    }
    @media screen and (max-width: 920px) {
        left: 20px;
        width: 95vw;
    }

    @media screen and (max-width: 750px) {
        left: 10px;
        width: 95vw;
    }
    input {
        height: 59px;
        font: var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-outfit);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---090909-dt-background-and-title-for-all);
        text-align: left;
        font: normal normal 600 14px/24px Outfit;
        letter-spacing: 0px;
        color: #090909;
        border: none;
    }
    img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.5s;
    }
}

.suggestion-box {
    position: absolute;
    top: 73px;
    left: 415px;
    width: 1090px;
    min-height: 270px;
    background: var(---ffffff-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(---303030-dt-border);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #303030;
    border-radius: 10px;
    z-index: 10;
    transition: all 0.5s;
    @media screen and (max-width: 1280px) {
        left: 95px;
    }
    @media screen and (max-width: 1025px) {
        left: 25px;
        width: 95vw;
    }
    @media screen and (max-width: 920px) {
        left: 20px;
        width: 95vw;
    }
    @media screen and (max-width: 750px) {
        left: 10px;
        width: 95vw;
    }

    box-shadow: 3px 4px 9px 0px #2c2c2b36;
    img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        opacity: 1;
    }
    h6 {
        width: 70px;
        height: 18px;
        font: var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-14) / var(--unnamed-line-spacing-24)
            var(--unnamed-font-family-outfit);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---090909-dt-background-and-title-for-all);
        text-align: left;
        font: normal normal 600 14px/24px Outfit;
        letter-spacing: 0px;
        color: #090909;
        opacity: 1;
    }
    h4 {
        width: 65%;
        height: 27px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16) /
            var(--unnamed-line-spacing-30) var(--unnamed-font-family-outfit);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---090909-dt-background-and-title-for-all);
        text-align: left;
        font: normal normal bold 16px/30px Outfit;
        letter-spacing: 0px;
        color: #090909;
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0px;
        padding-bottom: 0.5rem;
    }
    .paragraph {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
        height: 21px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px /
            var(--unnamed-line-spacing-26) var(--unnamed-font-family-outfit);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---f71137-primary);
        text-align: left;
        font: normal normal bold 12px/26px Outfit;
        letter-spacing: 0px;
        color: #f71137;
        opacity: 1;
    }
}

.suggestion-Box-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
