/** @format */

@import "../variables";
body.light-theme .header-wrapper.stickey .navbar-light .nav-link {
  color: #ffffff !important;
}
.header-wrapper {
  transition: all 0.3s;
  .navbar {
    padding: 0 30px;
    margin: 10px 0;
    @media screen and (max-width: 991px) {
      padding: 0;
    }
  }

  &.stickey {
    position: fixed;
    width: 100%;
    background: $secondary;
    z-index: 1000;
    top: 0;
    animation: mymove 0.3s;
    .navbar-brand {
      color: #ffffff !important;
    }
    .navbar-toggler {
      color: #ffffff !important;
      border-color: #ffffff !important;
      background-color: #ffffff !important;
    }
    .navbar-light {
      .user-dropdown {
        .user-link {
          svg {
            color: $white !important;
          }
        }
      }
      .nav-link {
        color: #ffffff !important;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #ffffff !important;
          &:hover,
          &:focus,
          &:active {
            color: $primary !important;
          }
        }
      }
    }
  }
  .navbar-light {
    .navbar-brand {
      color: #ffffff;
      font-size: 20px;
      line-height: 25px;
      margin-right: 40px;
      &:hover,
      &:focus-visible,
      &:focus,
      &:active {
        color: $primary !important;
        outline: none;
      }
    }
    .navbar-toggler {
      color: #ffffff;
      border-color: #ffffff;
      background-color: #ffffff;
    }
    .navbar-nav {
      .nav-link {
        color: #ffffff;
        font-size: 16px;
        line-height: 20px;
        // font-weight: 300;
        font-weight: 400;
        padding: 8px 20px;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
        @media screen and (max-width: 767px) {
          color: #ffffff;
          &:hover,
          &:focus,
          &:active {
            color: $eleven !important;
          }
        }
      }
      .nav-link2 {
        color: $secondary;
        font-size: 16px;
        line-height: 20px;
        // font-weight: 300;
        font-weight: 400;
        padding: 14px 20px;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }

    .user-dropdown {
      .user-link {
        font-size: 20px;
        svg {
          margin-top: -4px;
        }
      }
      .dropdown-item {
        &:active {
          background: none !important;
        }
        .nav-link {
          color: $secondary !important;
          &.text-primary {
            color: $primary !important;
          }
        }
      }
      .dropdown-menu {
        left: -150px;
        :hover {
          border-radius: 4px;
        }
      }
      .user-profile-image {
        width: 28px;
        height: 28px;
        margin-top: -4px;
        object-fit: cover;
        padding: 2px;
        border: 1px solid #dadce0;
        border-radius: 50%;
      }
      .flag-img {
        width: 18px;
        height: 12px;
      }
      .dark-border {
        border: 1px solid #090909;
      }
      .dark-ligth {
        border: 1px solid #fff;
      }
    }

    .user-dropdown2 {
      .user-link {
        font-size: 20px;
        svg {
          margin-top: -4px;
        }
      }
      .dropdown-item {
        &:active {
          background: none !important;
        }
        .nav-link {
          color: $secondary !important;
          &.text-primary {
            color: $primary !important;
          }
        }
      }
      .dropdown-menu {
        left: -20px;
        :hover {
          border-radius: 4px;
        }
      }
      .user-profile-image {
        width: 28px;
        height: 28px;
        margin-top: -4px;
        object-fit: cover;
        padding: 2px;
        border-radius: 50%;
      }
      .flag-img {
        width: 18px;
        height: 12px;
      }
      .dark-border {
        border: 1px solid #090909;
      }
      .dark-ligth {
        border: 1px solid #fff;
      }
    }

    .navbar-collapse {
      padding-left: 15px;
      border-left: 1px solid $seventh;
      align-items: center;
      ul {
        &.navbar-nav {
          align-items: left;
        }
      }
      @media screen and (max-width: 767px) {
        padding-left: 0px;
        border-left: 0px solid $seventh;
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        position: absolute;
        background: $primary;
        width: 100%;
        top: 60px;
        left: 0;
        z-index: 9;
        box-shadow: 1px 20px 20px 8px rgba($color: #000000, $alpha: 0.76);
      }
    }
  }
}

.icon-dark-color {
  color: "#090909";
}

@keyframes mymove {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

.bottom-element-wrapper {
  display: none;
  position: fixed;
  background-color: $secondary;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  @media screen and (max-width: 767px) {
    display: block;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0;
    li {
      padding: 10px;
    }
  }
  button {
    padding: 0;
  }
}
.flag-drp {
  .dropdown-menu {
    min-width: 70px;
    width: 160px;
    padding: 0px;
    right: 0 !important;
    left: auto !important;
  }
  .flag-item {
    padding: 10px 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 25px;
      margin-right: 10px;
    }
    border-bottom: 1px solid $primary;
    font-size: 18px;
    &:last-child {
      border-bottom: 0px solid $primary;
    }
  }
  .flag-item-main {
    img {
      width: 25px;
    }
  }
}
.mobile-user-links {
  .user-dropdown {
    list-style-type: none;
    .dropdown-menu {
      left: auto !important;
      right: 0;
    }
    .user-link {
      font-size: 20px;
      padding: 0;
      transition: all ease 1s;
      svg {
        color: #ffffff;
        transition: all ease 1s;
      }
      &:hover {
        svg {
          color: $primary;
          transition: all ease 1s;
        }
      }
    }
  }
}
