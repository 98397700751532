@import "../variables";
.custom-toggle {
    display: flex;
    .text {
        font-size: 14px;
        color: $white;
        margin-right: 10px;
    }
    input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 20px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 2px;
        width: 20px;
        height: 18px;
        background: $white;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label {
        background: $primary;
    }

    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 24px;
    }
}
