@import "../variables";

.payment-modal {
  position: relative;
  min-width: 600px;
  position: relative;
  @media screen and (max-width: 1024px) {
    min-width: auto;
  }
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.47);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    @media screen and (max-width: 767px) {
      top: 5px;
      right: 0px;
    }
  }
  .modal-content {
    background-color: $white;
    padding: 40px;
    border: none;
    max-height: max-content;
    @media screen and (max-width: 767px) {
      padding: 35px 20px;
    }
    // p {
    //   @media screen and (max-width: 767px) {
    //     font-size: 20px;
    //   }
    // }
    .payment-options {
      padding-bottom: 15px;
      @media screen and (max-width: 767px) {
        padding-bottom: 0px;
      }
    }
  }
}
