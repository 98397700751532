/** @format */

@import "../variables";
$green: #4cd964;
$turquoise: #5ac8fa;
$blue: #007aff;
$light-blue: #7dc8e8;
$purple: #5856d6;
$red: #ff2d55;
.uploading-wrapper {
  min-height: calc(100vh - 340px);
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 210px);
  }
}

.progress-bar-wrapper {
  width: 480px;
  margin: 40px auto;
  .progress {
    padding: 0px;
    background: rgba(0, 0, 0, 0.25);
    height: 10px;
  }

  .progress-bar {
    height: 10px;
    background-color: #ee303c;
    border-radius: 4px;
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .progress-moved {
    width: 85%;
    background-color: #ef476f;
    animation: progressAnimation 6s;
  }

  @keyframes progressAnimation {
    0% {
      width: 5%;
      background-color: #f9bcca;
    }
    100% {
      width: 85%;
      background-color: #ef476f;
    }
  }

  $green: #4cd964;
  $turquoise: #5ac8fa;
  $blue: #007aff;
  $light-blue: #7dc8e8;
  $purple: #5856d6;
  $red: #ff2d55;

  .progress-bar3 {
    height: 10px;
    border-radius: 4px;
    background-image: linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .progress-bar3 {
    width: 100%;
    background-image: linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
    animation: colorAnimation 6s infinite;
  }

  @keyframes colorAnimation {
    0% {
      background-image: linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
    }
    20% {
      background-image: linear-gradient(to right, $turquoise, $blue, $light-blue, $purple, $red, $green);
    }
    40% {
      background-image: linear-gradient(to right, $blue, $light-blue, $purple, $red, $green, $turquoise);
    }
    60% {
      background-image: linear-gradient(to right, $light-blue, $purple, $red, $green, $turquoise, $blue);
    }
    100% {
      background-image: linear-gradient(to right, $purple, $red, $green, $turquoise, $blue, $light-blue);
    }
  }
  @media screen and (max-width: 767px) {
    width: 280px;
    margin: 40px auto;
  }
}
