.custom-select-design {
    .container-div {
        padding: 21px;
    }
    .label-logo {
        img {
            max-width: 111px;
            margin-left: 27px;
            max-height: 26px;
        }
    }
}

.css-9gakcf-option {
    // background-color: #2684FF;
    background: #191919 !important;
}

.custom-select-design {
    display: inline-flex;
    width: 100%;
    &.nft-select {
        width: 50%;
        .container-div {
            padding: 0px;
        }
        .label-logo {
            img {
                max-width: 40px;
                height: 40px;
                margin: 0 auto;
            }
        }
        .image-wrapper {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
        p {
            text-align: center;
            color: #929292;
        }
    }
}

.ctm-tag {
    .css-1n7v3ny-option {
        color: #929292;
    }
    .css-yt9ioa-option {
        color: #929292;
    }
    .css-1rhbuit-multiValue {
        background: #ffffff !important;
        border-radius: 5px;
    }
    .css-12jo7m5 {
        color: #f71137 !important;
    }
    .css-xb97g8 {
        background-color: #929292 !important ;
        padding-left: 1px;
        padding-right: 1px;
        box-sizing: border-box;
        border-radius: 50%;
        margin: 6px;

        .css-tj5bde-Svg {
            color: #ffffff !important;
        }
    }
}
