@import "../variables";

.expiry-month__single-value,
.expiry-year__single-value {
    font-weight: 400 !important;
    font-size: 18px;
}
.payment-flow-wrapper {
    min-height: calc(100vh - 255px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    .payment {
        .payment-heading {
            font-size: 36px;
            font-weight: bold;
            margin: 30px 0px;
        }
        p {
            margin-bottom: 20px;
        }
        button {
            margin-top: 30px;
        }
    }
}
.invoice-wrapper {
    min-height: calc(100vh - 255px);
    margin: 50px 0;

    h2 {
        font-size: 36px;
        font-weight: bold;
    }

    table {
        min-width: 900px;
        thead {
            // border-bottom: 1px solid $ninth;
            td {
                font-weight: bold;
                height: 58px;
                padding-bottom: 16px;
            }
        }
        & > :not(:first-child) {
            border-top: 1px solid $ninth;
        }
        tbody {
            td {
                height: 58px;
                padding-top: 16px;
            }
        }
    }
}
