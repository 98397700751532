/** @format */
@import "../variables";
// body {
//   background-color: #ffffff !important;
//   background-image: none !important;
// }
.create-wrapper {
  // min-height: calc(100vh - 340px);
  min-height: calc(100vh - 242px);
  display: flex;
  // align-items: center;
  &.margin-top-reset {
    margin: 50px 0;
  }

  .upload-main-wrapper {
    border: 1px solid $ninth;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    flex-direction: column;
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      overflow: hidden;
      margin-bottom: 40px;
    }
    .title {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 1199px) {
      margin-bottom: 0px;
      .img-wrapper {
        width: 60px;
        height: 60px;
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
      .img-wrapper {
        width: 50px;
        height: 50px;
      }
    }
  }
  .btn-block {
    margin-top: 50px;
  }
  &.create-nft-select-wrap {
    .upload-main-wrapper {
      .img-wrapper {
        width: 70px !important;
      }
    }
  }
}

// loading dots animation
.loading-dots-wrapper {
  #dot1 {
    animation: load 1s infinite;
  }

  #dot2 {
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  #dot3 {
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.modal-content {
  background: #ffffff;
  border-radius: 10px;
  .modal-body {
    padding: 40px;
    @media screen and (max-width: 480px) {
      padding: 15px;
    }
  }
}
.close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  svg {
    transition: all ease 0.5;
  }
  &:hover {
    svg {
      transition: all ease 0.5;
      path {
        fill: $primary;
      }
    }
  }
}
.content {
  margin: 40px 0;
  text-align: center;
  .img-wrapper {
    width: 260px;
    height: 260px;
    border-radius: 5px;
    margin: 0 auto;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      width: 150px;
      height: 150px;
    }
  }
}

.main-title {
  margin-bottom: 40px;
}
.modal-backdrop {
  backdrop-filter: blur(50px) !important;
  -webkit-backdrop-filter: blur(50px);
  background: $ninth;
}

.modal-backdrop.show {
  opacity: 0.9;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

.menu-block {
  position: absolute;
  top: 10px;
  right: 10px;
  .dropdown-menu {
    left: auto !important;
    transform: none !important;
    right: 11px !important;
    top: 35px !important;
    inset: none !important;
    border: 0;
    background-color: #f8f8f8;
    padding: 0;
    border-radius: 5px;
    &:hover {
      border-radius: 5px !important;
    }
    .dropdown-item {
      font-size: 13px;
      padding: 20px;
      color: $forth;
      &:hover {
        border-radius: 5px !important;
        background-color: #f8f8f8;
      }
    }
  }
}

.custome-hover {
  &:hover {
    background-color: rgba(0, 0, 0, 0.103);
  }
}

.custom-img-loader {
  position: absolute;
  top: 29.5%;
  left: 42.5%;
}

.custom-opacity {
  opacity: 0.4;
  pointer-events: none !important;
}

.error-title-createnft {
  margin-top: -10px;
  margin-bottom: 20px;
}
.disable {
  .custom-form-control,
  input {
    background-color: $WhiteLight;
    border-color: $ninth;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $forth;
      opacity: 0.5;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $forth;
      opacity: 0.5;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $forth;
      opacity: 0.5;
    }
  }
}
.dark-text-bold {
  color: $secondary !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px !important;
}
.custom-form-control {
  height: 63px;
  border-radius: 5px;
  border: 1px solid $ninth;
  padding: 0 20px;
  display: flex;
  align-items: center;
  .font-sz {
    color: #828282;
    opacity: 0.5;
  }
  input {
    border: 0;
    height: auto;
    opacity: 1;
    padding: 0 !important;
    width: auto;
    display: inline-flex;
    flex-grow: 1;
  }

  @media screen and (max-width: 767px) {
    .cy-bl {
      display: none;
    }
  }
}
.coming-soon {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
