@import "../variables";

.material-scrolltop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow: hidden;
    width: 56px;
    height: 56px;
    bottom: 23px;
    right: 50px;
    padding: 0;
    overflow: hidden;
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
    cursor: hand;
    border-radius: 50%;
    background: $fifth;
    transform: translateY(150px);
    transform-origin: bottom;
    transition: all 0.5s;
    z-index: 9;

    &:hover {
        background-color: $fifth;
        filter: brightness(2);
        text-decoration: none;
        box-shadow: 0 3px 10px rgb(0 0 0 / 50%), 0 3px 15px rgb(0 0 0 / 50%);
    }

    svg {
        font-size: 20px;
        color: $primary;
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &.reveal {
        // animation: come-in 0.8s ease forwards;
        transform: translateY(-23px);
    }

    @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        right: 15px;
    }
}
@keyframes come-in {
    to {
    }
}
