/** @format */
@import "../variables";
.detail-wrapper {
  .detail-banner-img-wrapper {
    position: relative;
    height: 640px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .favorite-ic-block {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      background-color: hsla(0, 0%, 100%, .5);
      border-radius: 50px;
      h3 {
        line-height: 20px !important;
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 574px) {
      height: 300px;
    }
  }
  .detail-banner-content {
    padding-top: 26px;
    .view-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 20px;
      .title {
        font-size: 26px;
        line-height: 26px;
        @media screen and (max-width: 991px) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      .icon {
        margin-right: 5px;
      }
      li {
        margin-right: 30px;
      }
    }
    .content-bn {
      margin: 30px 0 40px;
    }
    .counter-block {
      background-color: $fifth;
      border-radius: 20px;
      padding: 30px;
      .counter-wrap {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .block-2 {
          @media screen and (max-width: 767px) {
            margin-top: 15px;
          }
        }
      }
      .counter-comon {
        display: flex;
        flex-direction: column;
        .first-text {
          margin-bottom: 5px;
          line-height: 20px;
        }
        .second-text {
          margin-bottom: 5px;
          line-height: 30px;
          font-size: 30px;
          @media screen and (max-width: 991px) {
            font-size: 24px;
            line-height: 24px;
          }
        }
      }
      .ct-wrapper {
        display: flex;
        li {
          margin-right: 40px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .btn-block-wrapper {
        margin-top: 30px;
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

.details-content-wrapper {
  margin-top: 100px;
  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }
}
.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.capsual-blk {
  padding: 5px 20px 7px;
  background-color: $fifth;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  // text-transform: lowercase;
  display: inline-flex;
  border-radius: 18px;
  color: $third;
  transition: all ease 0.5s;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    color: $primary;
    transition: all ease 0.5s;
  }
}
/*provenance wrapper css started*/
.provenance-list {
  height: 265px;
  overflow: hidden;
  transition: all ease 0.5s;
  &.active {
    height: 100%;
    transition: all ease 0.5s;
  }
  .provenance-main-wrapper {
    margin-bottom: 10px;
  }
}
.seemore-link {
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
}
.provenance-main-wrapper {
  padding: 20px 30px;
  background-color: $fifth;
  display: flex;
  align-items: center;
  border-radius: 10px;
  .first-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .second-content {
    display: inline-flex;
    flex-grow: 1;
    margin: 0 30px 0 15px;
    h4 {
      line-height: 18px;
      margin-bottom: 5px;
    }
    p {
      line-height: 18px;
    }
    .block-1 {
      width: 70%;
    }
    .block-2 {
      width: calc(100% - 70%);
      text-align: end;
    }
  }
  .third-link-block {
    display: inline-flex;
    width: 18px;
    .link-cr {
      color: $third;
      transition: all ease 0.5s;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
}

/*creator-portion css started*/
.creator {
  background-color: $secondary;
  padding: 50px;
  border-radius: 20px;
  .content-block {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 574px) {
      display: block;
    }
    .first-block {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      margin-right: 30px;
      @media screen and (max-width: 574px) {
        display: block;
        margin: 0 auto;
      }
    }
    .second-block {
      display: inline-flex;
      flex-direction: column;
      width: calc(100% - 190px);
      align-items: flex-start;
      padding-top: 10px;
      .text-content {
        margin: 20px 130px 30px 0px;
        @media screen and (max-width: 1199px) {
          margin: 15px 70px 20px 0px;
        }
        @media screen and (max-width: 991px) {
          margin: 15px 20px 20px 0px;
        }
        @media screen and (max-width: 574px) {
          margin: 15px;
        }
      }
      h2 {
        font-size: 30px;
        line-height: 30px;
        @media screen and (max-width: 1199px) {
          font-size: 24px;
          line-height: 24px;
        }
      }
      @media screen and (max-width: 574px) {
        display: block;
        margin: 15px auto 0px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    padding: 30px;
  }
  @media screen and (max-width: 574px) {
    padding: 15px;
  }
}

.detail-collection-wrapper {
  border-radius: 10px;
  overflow: hidden;
  .img-wrapper {
    height: 305px;
    width: 100%;
    position: relative;
    @media screen and (max-width: 1199px) {
      height: 230px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .favorite-ic-block {
      cursor: pointer;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
      h3 {
        line-height: 20px !important;
      }
    }
  }
  .content-block {
    background-color: #ffffff;
    padding: 30px;
    @media screen and (max-width: 1199px) {
      padding: 15px;
    }
    .counter-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0px;
      margin-bottom: 30px;

      li {
        margin-right: 20px;
        .icon {
          margin-right: 10px;
        }
      }
      @media screen and (max-width: 1199px) {
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }
    .date-block {
      h4 {
        line-height: 26px;
      }
    }
  }
  .nft-checkbox-btn{
    width: 45px;
    height: 45px; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 50px;
    position: absolute;
    top: 15px;
    left: 15px;

    &.hidden{
      display: none;
    }

    .card-input {
      margin: 0px;
      padding: 0 0px;
      width: 100%;
      border: 0px solid $ninth;
      border-radius: 5px;
      height: 45px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    
    .card-input:hover {
      cursor: pointer;
    }
    .custom-check-btn {
      width: 18px;
      height: 18px;
      position: relative;
      border-radius: 5px;
      border: 2px solid $secondary;
      min-width: 18px;
      min-height: 18px;
      
      &:after {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        display: none;
        content: " ";
        transform: translate(-50%, -50%);
        background-color: $primary;
        border-radius: 3px;
      }
    }
    .card-input-element:checked + .card-input {
      border: 0px solid $primary;
      width: 100%;
      .custom-check-btn  {
        border: 2px solid $primary;
        &:after {
          display: block;
        }
      }
    }
    .card-input-element:disabled + .card-input {
      opacity: 0.5;
    }

  }
  @media screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.mediaModal {
  max-width: 600px;
  min-height: 80vh;
  border-radius: 10px;
  overflow: hidden;
}

.fixed-nft-warpper{
  position: fixed;
  bottom: -100px;
  width: 100%;
  background: #ffffff;
  padding: 20px 0;
  transition: all ease 0.3s;
  &.active{
    bottom: 0px;
    transition: all ease 0.3s;
  }
}